import DashboardActive from "../../assets/icons/Active_Dashboard.svg";
import Dashboard from "../../assets/icons/dashboard.svg";
import User from "../../assets/icons/user_1.svg";
import UserActive from "../../assets/icons/Active_User.svg";
import Product from "../../assets/icons/product.svg";
import ProductActive from "../../assets/icons/Active_Product.svg";
import Ip from "../../assets/img/ip.svg"
import IPActive from "../../assets/img/active_ip.svg"
import Recommendation from "../../assets/icons/recommendation.svg"
import RecommendationActive from "../../assets/icons/Active_recommendation.svg"
import styles from "./Sidebar.module.css";

export const menus = [
  {
    id: "null",
    title: "Dashboard",
    route: "/dashboard",
    img: Dashboard,
    imgActive: DashboardActive,
    imgAlt: "",
    className: `${styles.side_menu_img}`,
  },
  {
    id: "null",
    title: "User",
    route: "/user",
    img: User,
    imgActive: UserActive,
    imgAlt: "",
  },
  {
    id: "null",
    title: "Product",
    route: "/product",
    img: Product,
    imgActive: ProductActive,
    imgAlt: "",
  },
  {
    id: "null",
    title: "Blacklist IP",
    route: "/blacklistIP",
    img: Ip,
    imgActive: IPActive,
    imgAlt: "",
  },
  {
    id: "null",
    title: "Recommendations",
    route: "/recommendation",
    img: Recommendation,
    imgActive: RecommendationActive,
    imgAlt: "",
  },
];
