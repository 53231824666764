import { createStore, applyMiddleware, combineReducers } from "redux";
import LoginReducer from "./Reducers/LoginReducers";
import thunk from "redux-thunk";

const logger = ({ getState }) => {
  return (next) => (action) => {
    const returnValue = next(action);
    return returnValue;
  };
};
const rootReducer = combineReducers({
  auth: LoginReducer,
});

const Store = createStore(rootReducer, applyMiddleware(logger, thunk));
export default Store;
