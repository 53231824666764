import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as action from "../../Store/Actions/index";
import "antd/dist/antd.css";

export default function Logout (props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = "/";
  };

  return (
    <div>
        <h3>Logged In</h3>
        <button onClick={() => logout()}>CLICK TO LOGOUT</button>
    </div>
  );
};

