/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosInstance } from '../../utilities';
import { notification, Dropdown, Menu, Select, Switch } from 'antd';
import commonstyles from '../../styles/Management_Common.module.css';
import arrowDown from '../../assets/img/arrow_down.svg';
import Excel from '../../assets/img/excel.svg';
import defaultImg from '../../assets/img/default_img.png';
import Search from '../../assets/img/search_icon.svg';
import AntTableComponent from '../ReusableComponent/AntTableComponent';
import Pagination from '../ReusableComponent/Pagination';
import LoadingIndicator from '../ReusableComponent/LoadingIndicator';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { arrayMove } from 'react-movable';
import 'antd/dist/antd.css';
import * as action from '../../Store/Actions/index';
import MovableListComponent from '../ReusableComponent/MovableListComponent';
import { useDispatch } from 'react-redux';

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  // { _id: 2, status: 1, name: "User ID", value: "_id" },
  { _id: 3, status: 1, name: 'User', value: 'name' },
  { _id: 4, status: 1, name: 'Email', value: 'userId' },
  { _id: 5, status: 1, name: 'Phone', value: 'phone' },
  { _id: 11, status: 1, name: 'Country', value: 'country' },
  { _id: 12, status: 1, name: 'IP Address', value: 'ip' },
  { _id: 6, status: 1, name: 'Website', value: 'siteId' },
  { _id: 7, status: 1, name: 'Total Visits', value: 'totalHits' },
  { _id: 8, status: 1, name: 'Total Items', value: 'itemCount' },
  { _id: 9, status: 1, name: 'Interest', value: 'preference' },
  { _id: 13, status: 1, name: 'First Visit', value: 'firstHit' },
  { _id: 10, status: 1, name: 'Last Visit', value: 'lastHit' },
];

const filters = [
  { id: 1, isChecked: false, name: 'Website', value: 'website' },
  { id: 2, isChecked: false, name: 'Country', value: 'country' },
];

const websiteCompany = [
  { _id: 1, name: 'ALL', value: '' },
  { _id: 2, name: 'HGR', value: 'hgr' },
  { _id: 3, name: 'IGAM', value: 'igam' },
];

// const userType = [
//   { _id: 1, name: "ALL USER", value: "" },
//   { _id: 3, name: "GUEST USER", value: "1" },
//   { _id: 2, name: "LOGGED USER", value: "0" },
// ];

export default function UserList(props) {
  const dispatch = useDispatch();
  const urlPath = props.match.url;
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filter, setFilter] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [website, setWebsite] = useState(
    JSON.parse(sessionStorage.getItem('userList'))?.siteId || ''
  );
  const [country, setCountry] = useState(
    JSON.parse(sessionStorage.getItem('userList'))?.countriesFilter || ''
  );
  const [websiteType, setwebsiteType] = useState([]);
  const [countryType, setcountryType] = useState([]);

  // const [fetchUserType, setfetchUserType] = useState(userType);
  const [filterShipName, setFilterShipName] = useState('ALL');
  const [filterCategoryName, setFilterCategoryName] = useState('ALL');
  const [sortOrder, setSortOrder] = useState({
    sortField: '',
    sortValue: '',
  });
  const [columnSortField, setColumnSortField] = useState('');

  const [user, setUser] = useState('');
  const timeoutId = useRef();
  const [initialTime, setInitialTime] = useState(true);
  const [websiteChecked, setWebsiteChecked] = useState(false);
  const [countryChecked, setCountryChecked] = useState(false);

  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: 'Name',
      visible: false,
      key: 'name',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>{record?.name ? record?.name : ' -'}</span>
          </div>
        </NavLink>
      ),
    },

    {
      title: 'Email',
      visible: false,
      key: 'userId',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>{record?.userId ? record?.userId : '-'}</span>
          </div>
        </NavLink>
      ),
    },

    {
      title: 'Phone',
      visible: false,
      key: 'phone',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>{record?.phone ? record?.phone : '-'}</span>
          </div>
        </NavLink>
      ),
    },

    {
      title: 'Country',
      visible: false,
      key: 'country',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record?.location?.address?.state
                ? record?.location?.address?.state + ','
                : ''}{' '}
              {record?.location?.address?.country
                ? record?.location?.address?.country
                : ''}
            </span>
          </div>
        </NavLink>
      ),
    },
    {
      title: 'IP Address',
      visible: false,
      key: 'ip',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>{record?.ip ? record?.ip : '-'}</span>
          </div>
        </NavLink>
      ),
    },
    {
      title: 'First Visit',
      visible: false,
      key: 'firstHit',
      render: (text, record, index) => (
        <NavLink to={`/user/${record?._id}`}>
          <div>
            <span>
              {record?.firstHit
                ? moment.utc(record.firstHit).local().format('MM/DD/YYYY')
                : '-'}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
    {
      title: 'Last Visit',
      visible: false,
      key: 'lastHit',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record.lastHit
                ? moment.utc(record.lastHit).local().format('MM/DD/YYYY')
                : '-'}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
    {
      title: 'Visits',
      visible: false,
      key: 'totalHits',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record?.totalHits
                ? Intl.NumberFormat().format(record?.totalHits)
                : '0'}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
    {
      title: 'Items',
      visible: false,
      key: 'itemCount',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record?.itemCount
                ? Intl.NumberFormat().format(record?.itemCount)
                : '0'}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
    {
      title: 'Interest',
      visible: false,
      key: 'preference',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record?.preference
                ? Intl.NumberFormat().format(record?.preference)
                : '0'}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
    {
      title: 'Website',
      visible: false,
      key: 'siteId',
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>{record?.siteId ? record?.siteId : '-'}</span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === '/user' ? (a, b) => a - b : '',
    },
  ]);

  function onChange(pagination, filters, sorter, extra) {
    setColumnSortField(sorter.columnKey);
    let sortValue =
      sorter?.order === 'ascend'
        ? 'ASC'
        : sorter?.order === undefined
        ? ''
        : 'DESC';

    setSortOrder({
      ...sortOrder,
      sortField: sorter?.columnKey,
      sortValue: sortValue,
    });
    getUserList(
      pageSize,
      nextPage,
      website,
      true,
      {
        sortField: sorter?.columnKey,
        sortValue: sortValue,
      },
      search,
      user,
      country
    );
    console.log(sortOrder);
  }

  useEffect(() => {
    getWebsiteDetail();
    getCountryDetail();
    set_new_header(headers);

    if (props.match.url === '/frequentCustomer/user') {
      let filter_value = JSON.parse(sessionStorage.getItem('frequentList'));
      setPageSize(filter_value?.pageSize ?? 25);
      setNextPage(filter_value?.nextPage ?? 1);
      setChecked(true);
      setUser(filter_value?.guest);
      getUserList(
        filter_value?.pageSize || 25,
        filter_value?.nextPage || 1,
        filter_value?.siteId || '',
        '',
        filter_value?.sorter || '',
        search,
        0,
        filter_value?.countriesFilter || ''
      );
    } else {
      let user_value = JSON.parse(sessionStorage.getItem('userList'));
      setPageSize(user_value?.pageSize ?? 25);
      setNextPage(user_value?.nextPage ?? 1);
      setCountry(user_value?.countriesFilter || '');
      setUser(user_value?.guest);
      getUserList(
        user_value?.pageSize || 25,
        user_value?.nextPage || 1,
        user_value?.siteId || '',
        '',
        user_value?.sorter || '',
        search,
        user_value?.guest,
        user_value?.countriesFilter || ''
      );
    }

    localStorage.removeItem('selectedIds');
    return () => {
      // localStorage.removeItem("userList");
      localStorage.removeItem('selectedIds');
    };
  }, []);

  function getWebsiteDetail() {
    AxiosInstance(9000, 'application/json')
      .get(`/siteList`)
      .then((res) => {
        if (res && res.data && res.status == '200') {
          setwebsiteType(res.data?.data);
        } else if (res.status === '403') {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        const error = {
          type: 'error',
          message: 'Error',
          description: 'Failed to get website',
        };
        showNotification(error);
      });
  }
  function getCountryDetail() {
    AxiosInstance(9000, 'application/json')
      .get(`/countries`)
      .then((res) => {
        if (res && res.data && res.status == '200') {
          setcountryType(res.data?.data);
        } else if (res.status === '403') {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        const error = {
          type: 'error',
          message: 'Error',
          description: 'Failed to get category',
        };
        showNotification(error);
        // return er;
      });
  }
  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || 'Message',
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const logout = () => {
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = '/';
  };

  // *** FETCH USER LIST *** //
  function getUserList(
    pageSize,
    nextPage,
    filterarr,
    type,
    sorter,
    search,
    guestCatche,
    countriesFilter
  ) {
    if (!type) {
      setIsLoading(true);
    }
    var siteId = filterarr;
    var countries = countriesFilter;
    var guest = guestCatche;
    var sortField = sorter.sortField || '';
    var sortValue = sorter.sortValue || '';
    // if (filterarr && filterarr.name === "website") {
    //   siteId = filterarr?.value?._id === "all" ? "" : filterarr?.value?._id;
    // }

    // if (sorter !== "") {
    //   sortField = sorter.sortField;
    //   sortValue = sorter.sortValue;
    // }

    if (props.match.url === '/user') {
      var user_value = {
        pageSize: pageSize,
        nextPage: nextPage,
        siteId: siteId,
        sorter: sorter,
        guest: guest,
        countriesFilter: countriesFilter,
      };
    } else {
      var filter_value = {
        pageSize: pageSize,
        nextPage: nextPage,
        siteId: siteId,
        sorter: sorter,
        countriesFilter: countriesFilter,
      };
    }

    var api;
    if (props.match.url === '/user') {
      sessionStorage.setItem('userList', JSON.stringify(user_value));
      api = `users/getUsers?siteId=${siteId}&guest=${guest}&pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=${sortField}&sortValue=${sortValue}&search=${
        search || ''
      }&country=${countries}`;
    } else {
      sessionStorage.setItem('frequentList', JSON.stringify(filter_value));

      api = `users/getUsers?siteId=${siteId}&guest=${guest}&pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=totalHits&sortValue=${sortValue}&search=${
        search || ''
      }&country=${countries}`;
    }

    AxiosInstance(9000, 'application/json')
      .get(api)
      .then((res) => {
        if (res && res.data && res.status === 200) {
          console.log(res);
          if (res.data.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item['key'] = indx + 1;
              item['isChecked'] = false;
            });
            // localStorage.setItem("userList", JSON.stringify(res.data?.data));
            setUserList(res.data?.data);
            setPagination(res.data.pagination);
          } else {
            setUserList([]);
            setPagination({});
          }
        } else if (res?.data && res?.status == 403) {
          console.log('first');
          logout();
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  function handleInput(e) {
    const { name, value } = e.target;
    if (name.trim() === 'search') {
      getUserList(pageSize, 1, website, '', '', value, user, country);
      setSearch(value);
    }
  }

  function handlepaginationChanges(value, type) {
    console.log(sortOrder);
    if (type === 'pageSize') {
      setPageSize(value);
      setNextPage(1);
      getUserList(value, 1, website, false, sortOrder, search, user, country);
    } else if (type === 'nextPage') {
      setNextPage(value);
      getUserList(
        pageSize,
        value,
        website,
        false,
        sortOrder,
        search,
        user,
        country
      );
    }
  }
  // switch toggle button to filter guest user //
  const onChangeSwitch = (checked) => {
    if (checked === true) {
      setChecked(true);
      setUser(0);
      let filter_value = JSON.parse(sessionStorage.getItem('frequentList'));
      let user_value = JSON.parse(sessionStorage.getItem('userList'));
      if (props.match.url === '/user') {
        getUserList(
          user_value?.pageSize ?? 25,
          user_value?.nextPage ?? 1,
          website,
          '',
          sortOrder,
          search,
          0,
          country
        );
      } else {
        getUserList(
          filter_value?.pageSize ?? 25,
          filter_value?.nextPage ?? 1,
          '',
          '',
          sortOrder,
          search,
          0,
          country
        );
      }
    }
    if (checked === false) {
      setChecked(false);
      setUser('');
      let filter_value = JSON.parse(sessionStorage.getItem('frequentList'));
      let user_value = JSON.parse(sessionStorage.getItem('userList'));
      if (props.match.url === '/user') {
        getUserList(
          user_value?.pageSize ?? 25,
          user_value?.nextPage ?? 1,
          '',
          '',
          '',
          search,
          '',
          country
        );
      } else {
        getUserList(
          filter_value?.pageSize ?? 25,
          filter_value?.nextPage ?? 1,
          '',
          '',
          '',
          search,
          '',
          country
        );
      }
    }
  };

  function filterChange(name, value) {
    // const { name, value } = e.target;
    if (name === 'website') {
      setWebsite(value._id === 'all' ? '' : value._id);
      setFilterShipName(value.name);
    }
    getUserList(
      pageSize,
      nextPage,
      value._id === 'all' ? '' : value._id,
      false,
      true,
      search,
      user,
      country
    );
  }

  function categoryChange(name, value) {
    if (name === 'country') {
      setCountry(value._id === 'all' ? ' ' : value._id);
      setFilterCategoryName(value.name);
    }
    getUserList(
      pageSize,
      nextPage,
      website,
      false,
      true,
      search,
      user,
      value._id === 'all' ? ' ' : value._id
    );
  }
  //TO SHOW TABLE HEADERS BASED ON STATUS AND COLUMNS FILTER
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  // useEffect(() => {
  //   let user_value = JSON.parse(sessionStorage.getItem("userList"));

  //   getUserList(pageSize, 1, 'all', false, true, search, user, '');
  // }, [website])

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    if (data.value === 'website') {
      setWebsiteChecked(data.isChecked);
    } else {
      setCountryChecked(data.isChecked);
    }
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];

    cloneSelectFilter.forEach((item, index) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters.splice(index, 1);
        }
      }
    });

    setSelectedFilters(cloneSelectedFilters);
    if (!e.target.checked && data.id === 1) {
      setWebsite('');
      getUserList(pageSize, nextPage, '', false, true, search, user, country);
    }
    if (!e.target.checked && data.id === 2) {
      getUserList(pageSize, nextPage, website, false, true, search, user, '');
      setCountry('');
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj.name = item.name;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: 'none' }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.name !== 'select' && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span>{value.name ? value.name : ''}</span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const filterMenu = (
    <Menu className="filter_dropdown user_Fliter">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{' '}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {!websiteChecked &&
              selectedFilters.find((x) => x === 'website') === 'website' && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: '100%' }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterShipName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(websiteType)
                      ? websiteType.map((item) => (
                          <span
                            key={item._id}
                            onClick={() => filterChange('website', item)}
                          >
                            <label style={{ textTransform: 'uppercase' }}>
                              {item.name}
                            </label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {!countryChecked &&
              selectedFilters.find((x) => x === 'country') === 'country' && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: '100%' }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterCategoryName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(countryType)
                      ? countryType.map((item) => (
                          <span
                            key={item._id}
                            onClick={() => categoryChange('country', item)}
                          >
                            <label>{item.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  const exportExcel = () => {
    AxiosInstance(9000, 'application/json')
      .get(
        `users/excel?siteId=${website}&search=${search}&guest=${user}&sortField=${sortOrder.sortField}&sortValue=${sortOrder.sortValue}&country=${country}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `User.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((er) => {
        return er;
      });
  };

  return (
    <div className={commonstyles.management_layout}>
      <div className={commonstyles.management_contents}>
        <div className={commonstyles.management_header}>
          <div className="row">
            <div className="col-lg-10 col-md-8 col-sm-8 col-xs-3">
              <h1>
                {props.match.url == '/user' ? 'Users' : 'Frequent Customers'}
              </h1>
              {/* <p className={commonstyles.management_header_P}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
            <div
              className="col-lg-2 col-md-4 col-sm-4 col-xs-9"
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <div>
                {props.match.url === '/user' ? (
                  <Switch
                    // className={commonstyles.btntoggle}
                    checkedChildren="Logged Users"
                    unCheckedChildren="All Users"
                    onChange={onChangeSwitch}
                    defaultChecked
                    checked={user === 0}
                  />
                ) : (
                  <Switch
                    // className={commonstyles.btntoggle}
                    checkedChildren="Logged Users"
                    unCheckedChildren="All Users"
                    onChange={onChangeSwitch}
                    defaultChecked
                    checked={checked === true}
                  />
                )}
              </div>
              <div>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    cursor: 'pointer',
                    font: '600 14px Poppins',
                  }}
                  onClick={exportExcel}
                >
                  <img style={{ marginRight: '5%' }} src={Excel} alt="export" />
                  <span>Export</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                name="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search by Name, Email and Phone"
                aria-describedby="search-addon"
                value={search}
                // onChange={}
                onChange={handleInput}
              />
              <img src={Search} alt="" />
            </div>

            <Dropdown
              overlay={filterMenu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div className="ant-dropdown-link">
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>

            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>

          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading === true ? (
              <div className={commonstyles.loadingPage}>
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching User details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,

                  sortDirections: ['descend', 'ascend'],

                  dataSource: userList,
                  pagination: false,
                  onChange: onChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list && Array.isArray(userList) && userList.length ? (
          <Pagination
            pageSizes={pageSizes}
            data={pagination}
            pageSize={pageSize}
            nextPage={nextPage}
            handlepaginationChanges={(value, type) => {
              setInitialTime((prev) => !prev);
              handlepaginationChanges(value, type);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
