import React from "react";
const SelectComponent = (props) => {
  let classes = "form-control theme-form-control";
  if (props.className) {
    classes = `${classes} ${props.className}`;
  }
  if (props.error) {
    classes = `${classes} theme-error-input`;
  }

  return (

    <select
      autoComplete="off"
      type="text"
      {...props.attributes}
      className={classes}
     
      
      
    >
      {props.children}
    </select>
    
    
  );
};

export default SelectComponent;