/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import {
  notification,
  Dropdown,
  Menu,
  Select,
  Button,
  Tooltip,
  Switch,
} from "antd";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import AntSelectComponent from "../ReusableComponent/AntSelectComponent";
import commonstyles from "../../styles/Management_Common.module.css";
import arrowDown from "../../assets/img/arrow_down.svg";
import defaultImg from "../../assets/img/default_img.png";
import Search from "../../assets/img/search_icon.svg";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { arrayMove } from "react-movable";
import "antd/dist/antd.css";
import MovableListComponent from "../ReusableComponent/MovableListComponent";
import BackArrow from "../../assets/icons/back_arrow.svg";
import Excel from "../../assets/img/excel.svg";
import heartactive from "../../assets/icons/Iconly-Heart.svg";
import heartInactive from "../../assets/icons/Iconly-Bold-Heart.svg";
import info from "../../assets/img/info_icon.svg";
import Re_Direct from "../../assets/icons/redirect.svg";
import defaultProductImg from "../../assets/images/img-about.png";
import GoogleMapsComponent from "../GoogleMapsComponent/googleMaps";

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Inventory No", value: "itemId" },
  { _id: 3, status: 1, name: "Product", value: "image" },
  { _id: 4, status: 1, name: "Price", value: "price" },
  { _id: 11, status: 1, name: "Category", value: "category" },
  { _id: 13, status: 1, name: "Location", value: "address" },
  { _id: 6, status: 1, name: "Total Visits", value: "totalHits" },
  { _id: 7, status: 1, name: "Interest Score", value: "preference" },
  { _id: 9, status: 1, name: "Distance", value: "distance" },
  { _id: 10, status: 1, name: "Favorite", value: "isFavorite" },
  { _id: 8, status: 1, name: "Last Visits", value: "lastHit" },
];
const initialFormState = {
  sign: "",
  price: "",
};

export default function UserDetail(props) {
  const history = useHistory();
  const { id } = useParams();
  const [userDetailList, setUserList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [editForm, setEditForm] = useState(initialFormState);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [sortOrder, setSortorder] = useState({
    sortField: "",
    sortValue: "",
  });
  const [notSold, setNotSold] = useState("");
  const [columnSortOrder, setColumnSortOrder] = useState("");
  const [show, setShow] = useState(true);
  const timeoutId = useRef();
  const [initialTime, setInitialTime] = useState(true);

  const [columns, setColumns] = useState([
    {
      title: "Inventory No",
      visible: false,
      key: "itemId",
      width: "15%",
      render: (text, record, index) => (
        <div>
          <NavLink to={`/product/${record?.itemId?._id}`}>
            <span title={record?.itemId?._id ? record?.itemId?.itemId : ""}>
              {record?.itemId?.itemId ? record?.itemId?.itemId : "-"}
            </span>
          </NavLink>
          <a
            href={`https://hgrinc.com/productDetail/item/item/${record?.itemId?.itemId
                ? record?.itemId?.itemId.replaceAll("-", "")
                : "-"
              }/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className=""
              alt="redirect"
              style={{ marginLeft: "5px" }}
              src={Re_Direct}
            />
          </a>
        </div>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Product",
      visible: false,
      key: "image",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.itemId?.image ? (
                <img
                  className="user_image"
                  alt=""
                  src={record?.itemId?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htst")}
                />
              ) : (
                <img className="user_image" alt="" src={defaultProductImg} />
              )}
              <div className="hide">
                {record?.itemId?.image ? 
                  <img alt="" src={record?.itemId?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htsh")} /> : ""}
              </div>
              { record?.itemId?.name ? (record?.itemId?.name?.length > 15 ? 
              <Tooltip 
              title={record?.itemId?.name}
              >
                {record?.itemId?.name.slice(0, 15)}...
              </Tooltip> : record?.itemId?.name) : " -"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Price ($)",
      visible: false,
      key: "price",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.itemId?.price
                // ? dollarUSLocale.format(record?.itemId?.price.toFixed(0))
                ?  dollarUSLocale.format(record?.itemId?.price)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Category",
      visible: false,
      key: "category",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.itemId?.category
                ? record?.itemId?.category
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Location",
      visible: false,
      key: "address",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.itemId?.address
                ? record?.itemId?.address
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Visits",
      visible: false,
      key: "totalHits",
      render: (text, record, index) => (
        <div>
          <span>
            <NavLink to={`/product/${record?.itemId?._id}`}>
              <span className={commonstyles.listWidth}>
              {record?.totalHits
                ? Intl.NumberFormat().format(record?.totalHits)
                : "-"}
              </span>
            </NavLink>
            <Tooltip
              placement="topLeft"
              title={
                <>
                  <div>
                    {record?.pages &&
                      Object.entries(record?.pages).map(([key, val], index) => {
                        return (
                          <>
                            {record?.pages && (
                              <>
                                {index < 1 && (
                                  <p className="tooltipSpan"> Pages </p>
                                )}
                                <p className="tooltipPara">
                                  {key} : {val}
                                </p>
                              </>
                            )}
                          </>
                        );
                      })}
                  </div>
                  <div>
                    {record?.pageType &&
                      Object.entries(record?.pageType).map(
                        ([key, val], index) => {
                          return (
                            <>
                              {record?.pageType && (
                                <>
                                  {index < 1 && (
                                    <p className="tooltipSpan"> Page Type </p>
                                  )}
                                  <p className="tooltipPara">
                                    {key} : {val}
                                  </p>
                                </>
                              )}
                            </>
                          );
                        }
                      )}{" "}
                  </div>
                </>
              }
            >
              <img
                src={record?.totalHits ? info : ""}
                className=""
                width={"12px"}
                height={"12px"}
                style={{ marginLeft: "3px", marginBottom: "3px" }}
              />
            </Tooltip>
          </span>
        </div>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Interest",
      visible: false,
      key: "preference",
      render: (text, record, index) => (
        <div>
          <span>
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <span className={commonstyles.listWidth}>
              {record?.preference
                ? Intl.NumberFormat().format(record?.preference)
                : "0"}
          </span>
        </NavLink>
        <Tooltip
        placement="topLeft"
        title={
          <>
           
            <div>
              {record?.preferencePages &&
                Object.entries(record?.preferencePages).map(
                  ([key, val], index) => {
                    return (
                      <>
                        {record?.preferencePages && (
                          <>
                            {index < 1 && (
                              <p className="tooltipSpan"> Page Type </p>
                            )}
                            <p className="tooltipPara">
                              {key} : {val}
                            </p>
                          </>
                        )}
                      </>
                    );
                  }
                )}{" "}
            </div>
          </>
        }
      >
        <img
          src={record?.totalHits ? info : ""}
          className=""
          width={"12px"}
          height={"12px"}
          style={{ marginLeft: "3px", marginBottom: "3px" }}
        />
      </Tooltip>
      </span>
        </div>
      ),
      sorter: (a, b) => a - b,
    },
    {
      title: "Last Visits",
      visible: false,
      key: "lastHit",
      width: "15%",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.lastHit
                ? moment
                  .utc(record.lastHit)
                  .local()
                  .format("MM/DD/YYYY")
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a.lastHit - b.lastHit,
    },
    {
      title: "Distance (mi)",
      visible: false,
      key: "distance",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record?.distance ? Number.parseInt(record?.distance) : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a.distance - b.distance,
    },
    {
      title: "Favorite",
      visible: false,
      key: "isFavorite",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?.itemId?._id}`}>
          <div>
            <span>
              {record?.isFavorite === true ? (
                <img src={heartactive}></img>
              ) : (
                <img src={heartInactive}></img>
              )}{" "}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a - b,
    },
  ]);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  // console.log("--------", columnSortOrder);

  useEffect(() => {
    set_new_header(headers);
    let filter_value = JSON.parse(sessionStorage.getItem("userDetailList"));
    // console.log(filter_value);
    getUserDetail(id);
    setPageSize(filter_value?.pagesize ?? 25);
    setNextPage(filter_value?.nextpage ?? 1);
    getUserList(
      filter_value?.pagesize ?? 25,
      filter_value?.nextpage ?? 1,
      false,
      filter_value?.sorter || "",
      filter_value?.soldout
    );
    setNotSold(filter_value?.soldout);
    localStorage.removeItem("selectedIds");

    return () => {
      localStorage.removeItem("userDetailList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  function onChange(pagination, filters, sorter, extra) {
    var sortValue =
    sorter?.order === "ascend"
    ? "ASC"
    : sorter?.order === undefined
    ? ""
    : "DESC";
    
    setSortorder({ sortField: sorter.columnKey, sortValue: sortValue });

    getUserList(pageSize, nextPage, true, {
      sortField: sorter?.columnKey,
      sortValue: sortValue,
    }, notSold, "");
  }
  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  // switch toggle button to filter guest user //
  const onChangeSwitch = (checked) => {
    if (checked === true) {
      let filter_value = JSON.parse(sessionStorage.getItem("userDetailList"));
      setNotSold(1);
      setPageSize(filter_value?.pagesize ?? 25);
      setNextPage(filter_value?.nextpage ?? 1);
      getUserList(
        filter_value?.pagesize ?? 25,
        filter_value?.nextpage ?? 1,
        false,
        filter_value?.sorter,
        1,
        ""
      );
    }
    if (checked === false) {
      setNotSold("");
      let filter_value = JSON.parse(sessionStorage.getItem("userDetailList"));
      setPageSize(filter_value?.pagesize ?? 25);
      setNextPage(filter_value?.nextpage ?? 1);
      getUserList(
        filter_value?.pagesize ?? 25,
        filter_value?.nextpage ?? 1,
        false,
        filter_value?.sorter,
        "",
        ""
      );
    }
  };
  // *** FETCH USER LIST *** //
  function getUserList(pageSize, nextPage, type, sorter, soldoutProducts, clearInput) {
    if (!type) {
      setIsLoading(true);
    }
    var soldout = soldoutProducts;
    var sortField = sortOrder.sortField;
    var sortValue = sortOrder.sortValue;
    var sign = clearInput ? clearInput?.sign :   editForm.sign;
    var price = clearInput ? clearInput?.price : editForm.price;
    console.log(clearInput, sign, price, "sign")
    if (sorter !== "") {
      sortField = sorter.sortField;
      sortValue = sorter.sortValue;
    }
    var filter_value = {
      sorter: sorter,
      soldout: soldout,
    };
    sessionStorage.setItem("userDetailList", JSON.stringify(filter_value));
    AxiosInstance(9000, "application/json")
      .get(
        `users/getUsers/${id}/items?pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=${sortField}&sortValue=${sortValue}&notSold=${soldout}&filter=${sign}&price=${price}`
      )
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            // console.log(res.data.data?.length);
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            localStorage.setItem(
              "userDetailList",
              JSON.stringify(res.data?.data)
            );
            setUserList(res.data?.data);
            setPagination(res.data.pagination);
          } else {
            setUserList([]);
            setPagination({});
          }
        } else if (res?.data && res?.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }
  const getUserDetail = (id) => {
    AxiosInstance(9000, "application/json")
      // .get(`/customers/getCustomer/${id}`)
      .get(`users/getUsers/${id}`)
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            // console.log(res.data.data?.length, "detail");
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            setDataSource(res.data?.data);
          } else {
            setDataSource([]);
            setPagination({});
          }
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(true);
      });
  };

  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getUserList(value, 1, filter, false, notSold);
    } else if (type === "nextPage") {
      setNextPage(value);
      getUserList(pageSize, value, filter, false, notSold);
    }
  }

  //TO SHOW TABLE HEADERS BASED ON STATUS AND COLUMNS FILTER
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  const renderBody = (props) => {
    // console.log(columns);
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    // console.log("fetch new order", newStatus);
    setItems(newStatus);
    set_new_header(newStatus);
  };

  function handleFormInput(e) {
    const { name, value } = e.target;
    console.log(e, "price1");
    setEditForm({ ...editForm, [name]: value });
  }
  function submitPrice(e) {
    const { name, value } = e.target;
    let data = {
      sign: editForm.sign,
      price: editForm.price,
    };
    //  var data1 = data.sign
    //  var data2 = data.price
{editForm === initialFormState ? "" : getUserList(pageSize, nextPage, false, "", notSold)}
    setShow(editForm === initialFormState ? true:  false)
  }
  console.log(editForm, "editForm");

  function clearPrice() {
   setEditForm(initialFormState)
   console.log(editForm, "editform2")
   getUserList(pageSize, nextPage, false, "", notSold, initialFormState);
   setShow(true)
  }

  const exportExcel = () => {    
    AxiosInstance(9000, "application/json")
      .get(`users/${id}/excel?notSold=${notSold}&sortField=${sortOrder.sortField}&sortValue=${sortOrder.sortValue}&filter=${editForm.sign}&price=${editForm.price}`, {
      responseType: 'blob'
      })
      .then(response => {
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `User.csv`) //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        })
      .catch((er) => {
        return er;
      })
  };

  return (
    <div className={commonstyles.management_layout}>
      <div className={commonstyles.management_contents}>
        <div className={commonstyles.management_header}>
          <div className="row" style={{alignItems: "center"}}>
            <div className="col-lg-10 col-md-8 col-sm-8 col-xs-4">
              <div className="back_arrow d-flex">
                <img
                  src={BackArrow}
                  onClick={() => history.goBack()}
                  className="cursor-pointer"
                  title="back"
                  width={"25px"}
                  height={"50px"}
                  style={{ marginTop: "9px" }}
                />
                <h1
                  className="backBtn-arrow"
                  style={{ font: "normal normal 600 32px/60px Poppins" }}
                >
                  User details
                </h1>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-8">
                  <span style={{display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", font: "600 14px Poppins"}} onClick={exportExcel}>
                    <img style={{marginRight: "1%"}} src={Excel} alt="export" />
                    <span>Export</span>
                  </span>
                </div>
          </div>
        </div>
        <div>
          <div>
            {dataSource.map((item) => (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <div className={commonstyles.management_contents_product}>
                      <div className="row">
                        <div className="col-lg-5">
                          <GoogleMapsComponent
                            lat={item?.location?.coordinates[1]}
                            lng={item?.location?.coordinates[0]}
                          />
                        </div>
                        <div className="col-lg-7">
                          <div class=" bd-highlight">
                            <div class="p-0 flex-fill bd-highlight d-flex">
                              <h1>Name : </h1>
                              <p>{item?.name ? item?.name : "-"}</p>
                            </div>
                            <div class="p-0 flex-fill bd-highlight d-flex">
                              <h1>Email : </h1>
                              <p>{item?.userId ? item?.userId : "-"}</p>
                            </div>
                            <div class="p-0 flex-fill bd-highlight d-flex">
                              <h1>Phone : </h1>
                              <p>{item?.phone ? item?.phone : "-"}</p>
                            </div>
                            <div class="p-0 flex-fill bd-highlight d-flex">
                              <h1>Location : </h1>
                              <p>
                                {item?.location?.address?.city
                                  ? item?.location?.address?.city + ", "
                                  : ""}
                                {item?.location?.address?.state
                                  ? item?.location?.address?.state + ", "
                                  : ""}
                                {item?.location?.address?.country
                                  ? item?.location?.address?.country
                                  : ""}
                              </p>
                            </div>
                            <div class="p-0 flex-fill bd-highlight d-flex">
                              <h1>IP Address : </h1>
                              <p>{item?.ip ? item?.ip : "-"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={commonstyles.management_contents_product}>
                      <div className="row">
                        <div className="col-lg-6  bd-highlight">
                          <h1>Website</h1>
                          <p style={{ textTransform: "uppercase" }}>
                            {item?.siteId ? item?.siteId : "-"}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <h1>First visit</h1>
                          <p>
                            {item.firstHit
                              ? moment
                                .utc(item.firstHit)
                                .local()
                                .format("MMM Do YYYY")
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h1>Total Visits</h1>
                          <p className="tooltipP">{item?.totalHits ? item?.totalHits : "-"}</p>
                          <Tooltip
                            placement="topLeft"
                            title={
                              <>
                                <div>
                                  {item?.pages &&
                                    Object.entries(item?.pages).map(([key, val], index) => {
                                      return (
                                        <>
                                          {item?.pages && (
                                            <>
                                              {index < 1 && (
                                                <p className="tooltipSpan"> Pages </p>
                                              )}
                                              <p className="tooltipPara">
                                                {key} : {val}
                                              </p>
                                            </>
                                          )}
                                        </>
                                      );
                                    })}
                                </div>
                                <div>
                                  {item?.pageType &&
                                    Object.entries(item?.pageType).map(
                                      ([key, val], index) => {
                                        return (
                                          <>
                                            {item?.pageType && (
                                              <>
                                                {index < 1 && (
                                                  <p className="tooltipSpan"> Page Type </p>
                                                )}
                                                <p className="tooltipPara">
                                                  {key} : {val}
                                                </p>
                                              </>
                                            )}
                                          </>
                                        );
                                      }
                                    )}{" "}
                                </div>
                              </>
                            }
                          >
                            <img
                              src={item?.totalHits ? info : ""}
                              className="cursor-pointer"
                              width={"12px"}
                              height={"12px"}
                              style={{ marginLeft: "3px", marginBottom: "3px" }}
                            />
                          </Tooltip>
                        </div>
                        <div className="col-lg-6">
                          <h1>Last visit</h1>
                          <p>
                            {item.lastHit
                              ? moment
                                .utc(item.lastHit)
                                .local()
                                .format("MMM Do YYYY")
                              : "-"}
                          </p>
                        </div>
                        
                      </div>
                      <div className="row">
                      <div className="col-lg-6  bd-highlight">
                          <h1>Total Items</h1>
                          <p>{item?.itemCount ? item?.itemCount : "-"}</p>
                        </div>
                      
                        
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-3  col-md-9 col-sm-9 col-xs-3">
              <h1 className={commonstyles.management_header_heading}>
                Items visited
              </h1>
            </div>
            <div className="col-lg-9 col-md-3 col-sm-3 col-xs-9">
              <div className="row mt-2">
                <div className="col-lg-4">
                  <AntSelectComponent
                    className="custom_select mt-1 mb-3 show"
                    attributes={{
                      name: "sign",
                      value: editForm?.sign,
                      onChange: handleFormInput,
                    }}
                  >
                    <option value="" disabled>
                      Price Select Option
                    </option>
                    <option value="gt">Price greater than $</option>
                    <option value="lt">Price lesser than $</option>
                    <option value="gte">Price greater than equal to $</option>
                    <option value="lte">Price lesser than equal to $</option>
                    <option value="eq">Price equal to $</option>
                    <option value="ne">Price not equal to $</option>
                  </AntSelectComponent>
                </div>
                <div className="col-lg-5 customfilter">
                  <InputFormComponent
                    className="custom_select   custominput"
                    formControl={{
                      placeholder: "Type the price",
                      type: "number",
                      name: "price",
                      value: editForm?.price,
                      onChange: handleFormInput,
                    }}
                  />
                  
                     <Button
                    type="submit"
                    onClick={submitPrice}
                    className="custombtn"
                  >
                    Search
                  </Button> 
                   <Button
                   type="submit"
                    onClick={clearPrice}
                    className="custombtn"
                  >
                    Clear
                  </Button>
                  
                  
                 
                </div>

                <div className="col-lg-3">
                  <Switch
                    // defaultChecked
                    className={commonstyles.btntoggle}
                    checkedChildren="Available Products"
                    unCheckedChildren="All Products"
                    onChange={onChangeSwitch}
                    checked={notSold === 1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading === true ? (
              <div className={commonstyles.loadingPage}>
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching User details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  sortDirections: ["descend", "ascend"],
                  columns: columns,
                  dataSource: userDetailList,
                  pagination: false,
                  onChange: onChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list &&
          Array.isArray(userDetailList) &&
          userDetailList.length ? (
          <Pagination
            pageSizes={pageSizes}
            data={pagination}
            pageSize={pageSize}
            nextPage={nextPage}
            handlepaginationChanges={(value, type) => {
              setInitialTime((prev) => !prev);
              handlepaginationChanges(value, type);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
