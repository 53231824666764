import React from "react";
import jwt from "jsonwebtoken";
import { Route, Redirect } from "react-router-dom";
//import { grantPermission } from "./Components/ReusableComponent/service";

const AuthGuard = ({
	component: Component,
	path,
	...rest
}) => {
	const isLoggedIn = jwt.decode(localStorage.getItem("token")) ? true : false;

	return (
		<Route
			{...rest}
			path={path}
			render={(props) => {
				return isLoggedIn ? (
					
						<Component {...props} />

				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

export default AuthGuard;
