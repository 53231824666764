import * as actionTypes from "./ActionTypes";
import { AxiosInstance } from "../../utilities";
import jwt from "jsonwebtoken";

export const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
  };
};

export const loginSuccess = (token, data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    data: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userdata");
  localStorage.removeItem("id");
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
    }, expirationTime * 1000);
  };
};

export const LoginActions = (loginForm, history = undefined) => {
  const historyExists = !!history;
  return (dispatch) => {
    dispatch(loginRequest());
    AxiosInstance(9000, "application/json")
      .post("/admin/login", loginForm)
      .then((response) => {
        // console.log(response);

        const {
          token,
          firstName,
          lastName,
          email,
          message,
        } = response.data?.data[0];

        // console.log(firstName);

        const userdetails = {
          firstName,
          lastName,
          email,
        };
        // console.log(userdetails);
        // console.log(localStorage.getItem("testObject"));
        if (response.status === "200" || response.status === 200) {
          // console.log('token',token);
          let istoken = token ? true : false;
          localStorage.setItem("istoken", istoken);
          if (true) {
            localStorage.setItem("token", token);
            localStorage.setItem("userdata", JSON.stringify(userdetails));
            // localStorage.setItem('testObject', JSON.stringify(userdetails));
            historyExists ? history.push("/dashboard") : (window.location.href = "/dashboard");
          }
        } else {
          // console.log('error');
          dispatch(loginFailure(message));
        }
      })
      .catch((e) => {
         console.log(e,"e");
         let msg = e?.response?.data?.message
        // console.log(msg);

        dispatch(loginFailure(msg));
      });
  };
};
// const company = JSON.parse(localStorage.getItem("companydata"))
// console.log("company",company)
export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    let decode = jwt.decode(token);
    if (!jwt.decode(token)) {
      dispatch(logout());
    } else {
      let dec = parseInt(decode.exp);
      if (dec <= (Date.now() / 1000).toFixed(0)) {
        dispatch(logout());
      } else {
        const data = localStorage.getItem("userdata");
        dispatch(loginSuccess(token, data));
      }
    }
  };
};
