import commonStyles from "../../styles/Management_Common.module.css";
import ReactPaginate from "react-paginate";
import leftArrow from "../../assets/img/pagination_left_arrow.svg";
import rightArrow from "../../assets/img/pagination_right_arrow.svg";
import PaginateWrapperComponent from "./PaginateWrapperComponent";

function generateCurrentpaginationText(pageSize, pagination) {
  // Result: 1 - 10 of 325
  if (pagination && Object.keys(pagination).length) {
    const { currentPage, totalItems } = pagination;

    let pageEnd = currentPage * pageSize;
    let pageStart = currentPage === 1 ? 1 : (currentPage - 1) * pageSize;

    return `Result: ${pageStart} - ${
      totalItems < pageEnd ? totalItems : pageEnd
    } of ${totalItems}`;
  }
  return "";
}

const prevIcon = <img src={leftArrow} alt="" />;

const nextIcon = <img src={rightArrow} alt="" />;

export default function pagination({
  pageSizes,
  pageSize,
  nextPage,
  data,
  handlepaginationChanges,
}) {
  function handleChange(value, type) {
    if (type === "pageSize") {
      handlepaginationChanges(Number(value), type);
    } else if (type === "nextPage") {
        console.log(value);
      handlepaginationChanges(value.selected + 1, type);
    }
  }

  return (
    <div className={commonStyles.table_footer}>
      <div className={commonStyles.table_pages}>
        <select
          id="inputState"
          value={pageSize}
          className={`${commonStyles.form_control}`}
          onChange={(e) => handleChange(e.target.value, "pageSize")}
        >
          {Array.isArray(pageSizes) &&
            pageSizes.map((size) => {
              return (
                <option value={size} key={size}>
                  {size}
                </option>
              );
            })}
        </select>
        <span>{generateCurrentpaginationText(pageSize, data)}</span>
      </div>
      <PaginateWrapperComponent
        data={data}
        nextPage={nextPage}
        handleChange={handleChange}
      />
    </div>
  );
}
