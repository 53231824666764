import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification, Dropdown, Menu, Select, Tooltip } from "antd";
import commonstyles from "../../styles/Management_Common.module.css";
import arrowDown from "../../assets/img/arrow_down.svg";
import Excel from "../../assets/img/excel.svg";
import defaultProductImg from "../../assets/images/img-about.png";
import info from "../../assets/img/info_icon.svg";
import Search from "../../assets/img/search_icon.svg";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import { arrayMove } from "react-movable";
import "antd/dist/antd.css";
import { Switch } from "antd";
import MovableListComponent from "../ReusableComponent/MovableListComponent";
import heartactive from "../../assets/icons/Iconly-Heart.svg";
import heartInactive from "../../assets/icons/Iconly-Bold-Heart.svg";
import BackArrow from "../../assets/icons/back_arrow.svg";
import Re_Direct from "../../assets/icons/redirect.svg";

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Name", value: "itemId" },
  { _id: 3, status: 1, name: "Email", value: "userId" },
  { _id: 10, status: 1, name: "Phone", value: "phone" },
  { _id: 4, status: 1, name: "Country", value: "country" },
  // { _id: 5, status: 1, name: "IP Address", value: "ip" },
  { _id: 5, status: 1, name: "Interest", value: "preference" },
  { _id: 6, status: 1, name: "Total Visits", value: "totalHits" },
  { _id: 7, status: 1, name: "Distance", value: "distance" },
  { _id: 8, status: 1, name: "Favorited", value: "isFavorite" },
  { _id: 9, status: 1, name: "Last Visit", value: "lastHit" },
];

export default function ProductDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [filterShipName, setFilterShipName] = useState("ALL");
  const [sortOrder, setSortorder] = useState({
    sortField: "",
    sortValue: "",
  });
  const [columnSortOrder, setColumnSortOrder] = useState("");
  const [login, setLogin] = useState("");
  const timeoutId = useRef();
  const [initialTime, setInitialTime] = useState(true);
  const [getProduct, setGetProduct] = useState([]);

  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Name",
      visible: false,
      key: "itemId",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>{record?.userId?.name ? record?.userId?.name : "-"}</span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Email",
      visible: false,
      key: "userId",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record?.userId?.userId ? ` ${record?.userId?.userId}` : "-"}
            </span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Phone",
      visible: false,
      key: "phone",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record?.userId?.phone ? ` ${record?.userId?.phone}` : "-"}
            </span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Country",
      visible: false,
      key: "country",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?._id}`}>
          <div>
            <span>
              {record?.userId?.location?.address?.state
                ? record?.userId?.location?.address?.state + ","
                : ""}{" "}
              {record?.userId?.location?.address?.country
                ? record?.userId?.location?.address?.country
                : ""}
            </span>
          </div>
        </NavLink>
      ),
    },
    // {
    //   title: "IP Address",
    //   visible: false,
    //   key: "ip",
    //   render: (text, record, index) => (
    //     <NavLink to={`/user/details/${record?._id}`}>
    //       <div>
    //         <span>{record?.userId?.ip ? record?.userId?.ip : "-"}</span>
    //       </div>
    //     </NavLink>
    //   ),
    // },
    // {
    //   title: "Website",
    //   visible: false,
    //   key: "siteId",
    //   render: (text, record, index) => (
    //     <NavLink to={`/user/details/${record?.userId?._id}`}>
    //       <div>
    //         <span>{record?.userId?.siteId ? record?.userId?.siteId : "-"}</span>
    //       </div>
    //     </NavLink>
    //   ),
    // },

    {
      title: "Interest",
      visible: false,
      key: "preference",
      render: (text, record, index) => (
        <>
          <NavLink to={`/user/details/${record?.userId?._id}`}>
            <span className={commonstyles.listWidth}>
              {record?.preference
                ? Intl.NumberFormat().format(record?.preference)
                : "0"}
            </span>
          </NavLink>
          <Tooltip
            placement="topLeft"
            title={
              <>
                <div>
                  {record?.preferencePages &&
                    Object.entries(record?.preferencePages).map(
                      ([key, val], index) => {
                        return (
                          <>
                            {record?.preferencePages && (
                              <>
                                {index < 1 && (
                                  <p className="tooltipSpan"> Page Type </p>
                                )}
                                <p className="tooltipPara">
                                  {key} : {val}
                                </p>
                              </>
                            )}
                          </>
                        );
                      }
                    )}{" "}
                </div>
              </>
            }
          >
            <img
              src={record?.totalHits ? info : ""}
              className=""
              width={"12px"}
              height={"12px"}
              style={{ marginLeft: "3px", marginBottom: "3px" }}
              alt="Tool tip"
            />
          </Tooltip>
        </>
      ),
      sorter: (a, b) => a.preference - b.preference,
    },
    {
      title: "Visits",
      visible: false,
      key: "totalHits",
      render: (text, record, index) => (
        <div>
          <span>
            <NavLink to={`/user/details/${record?.userId?._id}`}>
              <span className={commonstyles.listWidth}>
                {record?.totalHits
                  ? Intl.NumberFormat().format(record?.totalHits)
                  : "0"}
              </span>
            </NavLink>

            <Tooltip
              placement="topLeft"
              title={
                <>
                  <div>
                    {record?.pages &&
                      Object.entries(record?.pages).map(([key, val], index) => {
                        return (
                          <>
                            {record?.pages && (
                              <>
                                {index < 1 && (
                                  <p className="tooltipSpan"> Pages </p>
                                )}
                                <p className="tooltipPara">
                                  {key} : {val}
                                </p>
                              </>
                            )}
                          </>
                        );
                      })}
                  </div>
                  <div>
                    {record?.pageType &&
                      Object.entries(record?.pageType).map(
                        ([key, val], index) => {
                          return (
                            <>
                              {record?.pageType && (
                                <>
                                  {index < 1 && (
                                    <p className="tooltipSpan"> Page Type </p>
                                  )}
                                  <p className="tooltipPara">
                                    {key} : {val}
                                  </p>
                                </>
                              )}
                            </>
                          );
                        }
                      )}{" "}
                  </div>
                </>
              }
            >
              <img
                src={record?.totalHits ? info : ""}
                className=""
                width={"12px"}
                height={"12px"}
                style={{ marginLeft: "3px", marginBottom: "3px" }}
                alt="Tool tip"
              />
            </Tooltip>
          </span>
        </div>
      ),
      sorter: (a, b) => a.totalHits - b.totalHits,
    },
    {
      title: "Last Visit",
      visible: false,
      key: "lastHit",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record.lastHit
                ? moment
                    .utc(record.lastHit)
                    .local()
                    .format("MM/DD/YYYY")
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a.lastHit - b.lastHit,
    },
    {
      title: "Distance (mi)",
      visible: false,
      key: "distance",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record?.distance
                ? new Intl.NumberFormat().format(record?.distance)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: (a, b) => a.distance - b.distance,
    },
    {
      title: "Favorited",
      visible: false,
      key: "isFavorite",
      render: (text, record, index) => (
        <NavLink to={`/user/details/${record?.userId?._id}`}>
          <div>
            <span>
              {record?.isFavorite === true ? (
                <img className="" src={heartactive} title="Favorite"></img>
              ) : (
                <img className="" src={heartInactive} title="Unfavored"></img>
              )}{" "}
            </span>
          </div>
        </NavLink>
      ),
    },
  ]);

  let dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getProductDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/items/getItems/${id}`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          // console.log('datassss',res.data?.data?.itemId);
          setGetProduct(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get product details",
        };
        showNotification(error);
        // return er;
      });
  }

  function onChange(pagination, filters, sorter, extra) {
    console.log(pagination, filters, sorter, extra, "tableonchange");
    var sortValue =
      sorter?.order === "ascend"
        ? "ASC"
        : sorter?.order === undefined
        ? ""
        : "DESC";
    setSortorder({ sortField: sorter.columnKey, sortValue: sortValue });
    getproductList(pageSize, nextPage, true, {
      sortField: sorter?.columnKey,
      sortValue: sortValue,
      
    }, login);
  }

  // console.log("--------", columnSortOrder);

  useEffect(() => {
    set_new_header(headers);
    getProductDetail();
    let filter_value = JSON.parse(sessionStorage.getItem("productDetail"));
    setPageSize(filter_value?.pagesize ?? 25);
    setNextPage(filter_value?.nextpage ?? 1);

    getproductList(
      filter_value?.pagesize ?? 25,
      filter_value?.nextpage ?? 1,
      false,
      filter_value?.sorter || "",
      filter_value?.user
    );
    setLogin(filter_value?.user);
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("productList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  // switch toggle button to filter guest user //
  const onChangeSwitch = (checked) => {
    if (checked === true) {
      setLogin(1);
      let filter_value = JSON.parse(sessionStorage.getItem("productDetail"));
      setPageSize(filter_value?.pagesize ?? 25);
      setNextPage(filter_value?.nextpage ?? 1);

      getproductList(
        filter_value?.pagesize ?? 25,
        filter_value?.nextpage ?? 1,
        false,
        filter_value?.sorter || "",
        1
      );
    }
    if (checked === false) {
      setLogin("");
      let filter_value = JSON.parse(sessionStorage.getItem("productDetail"));
      setPageSize(filter_value?.pagesize ?? 25);
      setNextPage(filter_value?.nextpage ?? 1);

      getproductList(
        filter_value?.pagesize ?? 25,
        filter_value?.nextpage ?? 1,
        false,
        filter_value?.sorter || "",
        ""
      );
    }
  };

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** FETCH USER LIST *** //
  function getproductList(pageSize, nextPage, type, sorter, userLogin) {
    // setIsLoading(false);
    // console.log("pageSize", pageSize, "nextPage", nextPage, "type", type);
    // console.log(nextPage);
    if (!type) {
      setIsLoading(true);
    }
    var user = userLogin;
    var sortField = sortOrder.sortField;
    var sortValue = sortOrder.sortValue;

    if (sorter !== "") {
      sortField = sorter.sortField;
      sortValue = sorter.sortValue;
    }
    var filter_value = {
      nextPage: nextPage,
      pageSize: pageSize,
      sorter: sorter,
      user: user,
    };
    sessionStorage.setItem("productDetail", JSON.stringify(filter_value));
    AxiosInstance(9000, "application/json")
      .get(
        `items/getItems/${id}/users?pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=${sortField}&sortValue=${sortValue}&login=${user}`
      )
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            // console.log(res.data.data?.length);
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            localStorage.setItem("productList", JSON.stringify(res.data?.data));
            setProductList(res.data?.data);
            setPagination(res.data.pagination);
          } else {
            setProductList([]);
            setPagination({});
          }
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  function handlepaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getproductList(value, 1, true, filter, login);
    } else if (type === "nextPage") {
      setNextPage(value);
      getproductList(pageSize, value, true, filter, login);
    }
  }

  const exportExcel = () => {    
    AxiosInstance(9000, "application/json")
      .get(`items/${id}/excel?login=${login}&sortField=${sortOrder.sortField}&sortValue=${sortOrder.sortValue}`, {
      responseType: 'blob'
      })
      .then(response => {
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `User.csv`) //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        })
      .catch((er) => {
        return er;
      })
  };

  return (
    <div className={commonstyles.management_layout}>
      <div className={commonstyles.management_contents}>
      <div className="row" style={{alignItems: "center"}}>
      <div className="col-lg-10 col-md-8 col-sm-8 col-xs-4">
        <div className="back_arrow d-flex">
          <img
            src={BackArrow}
            onClick={() => history.goBack()}
            className="cursor-pointer"
            title="back"
            width={"25px"}
            height={"50px"}
            style={{ marginTop: "9px" }}
          />
          <h1
            className="backBtn-arrow"
            style={{ font: "normal normal 600 32px/60px Poppins" }}
          >
            Product details
          </h1>
        </div>
        </div>
          <div className="col-lg-2 col-md-4 col-sm-4 col-xs-8">
            <span style={{display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", font: "600 14px Poppins"}} onClick={exportExcel}>
              <img style={{marginRight: "1%"}} src={Excel} alt="export" />
              <span>Export</span>
            </span>
          </div>
        </div>
        <div className="row">
          {getProduct &&
            getProduct?.length > 0 &&
            getProduct?.map((item) => (
              <>
                <div className="col-lg-6">
                  <div className={commonstyles.management_contents_product}>
                    <div className="row">
                      <div className="col-lg-4">
                        <img
                          className="productImg"
                          alt="redirect"
                          src={item?.image ? item?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htsp") : defaultProductImg}
                        />
                      </div>
                      <div className="col-lg-8">
                        <div class=" bd-highlight">
                          <div class="p-0 flex-fill bd-highlight d-flex">
                            <h1>Inventory No : </h1>
                            <p>
                              {item?.itemId ? item?.itemId : "-"}{" "}
                              <a
                                href={`https://hgrinc.com/productDetail/item/item/${
                                  item?.itemId
                                    ? item?.itemId.replaceAll("-", "")
                                    : "-"
                                }/`}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <img
                                  className=""
                                  alt="redirect"
                                  style={{ marginLeft: "5px" }}
                                  src={Re_Direct}
                                />
                              </a>
                            </p>
                          </div>
                          <div class="p-0 flex-fill bd-highlight d-flex">
                            <h1>Product Name : </h1>
                            <p>{item?.name ? item?.name : "-"}</p>
                          </div>
                          <div class="p-0 flex-fill bd-highlight d-flex">
                            <h1>Product price : </h1>
                            <p>
                              {item?.price
                                ? dollarUSLocale.format(item?.price)
                                : "-"}
                            </p>
                          </div>
                          <div class="p-0 flex-fill bd-highlight d-flex">
                            <h1>Product Location : </h1>
                            <p>
                              {item?.location?.address
                                ? item?.location?.address
                                : "-"}
                            </p>
                          </div>
                          <div class="p-0 flex-fill bd-highlight d-flex">
                            <h1>Product Category : </h1>
                            <p>{item?.category ? item?.category : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={commonstyles.management_contents_product}>
                    <div className="row">
                      <div className="col-lg-6  bd-highlight">
                        <h1>Website</h1>
                        <p style={{ textTransform: "uppercase" }}>
                          {item?.siteId ? item?.siteId : "-"}
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <h1>Total Visits</h1>
                        <p>{item?.totalHits ? item?.totalHits : "-"}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6  bd-highlight">
                        <h1>Total Users</h1>
                        <p>{item?.userCount ? item?.userCount : "-"}</p>
                      </div>
                      <div className="col-lg-6">
                        <h1>Last visit</h1>
                        <p>
                          {item.lastHit
                            ? moment
                                .utc(item.lastHit)
                                .local()
                                .format("MMM Do YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        {/* <Switch defaultChecked onChange={onChangeSwitch} /> */}
        <div>
          <div className="row">
            <div className="col-lg-10  col-md-9 col-sm-9 col-xs-3">
              <h1 className={commonstyles.management_header_heading}>Users</h1>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-9">
              <br></br>
              <Switch
                // defaultChecked
                className={commonstyles.btntoggle}
                checkedChildren="Logged Users"
                unCheckedChildren="All Users"
                onChange={onChangeSwitch}
                checked={login === 1}
              />
            </div>
          </div>

          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading === true ? (
              <div className={commonstyles.loadingPage}>
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching Products details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  sortDirections: ["descend", "ascend"],
                  columns: columns,
                  dataSource: productList,
                  pagination: false,
                  onChange: onChange,
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list && Array.isArray(productList) && productList.length ? (
          <Pagination
            pageSizes={pageSizes}
            data={pagination}
            pageSize={pageSize}
            nextPage={nextPage}
            handlepaginationChanges={(value, type) => {
              setInitialTime((prev) => !prev);
              handlepaginationChanges(value, type);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
