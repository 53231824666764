import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import Logo from "../../assets/img/HTS-Logo.png";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification } from "antd";
import FormErrorText from "../FormErrorText";
import { Link, useParams } from "react-router-dom";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";
import { passwordReg, emailRegx, otpRegx } from "../RegularExp/RegularExp";
import BackGroundImg2 from "../../assets/img/bg_2.png";

export default function ForgotPassword() {
  const history = useHistory();
  const { code } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onVerifyOtp = (e) => {
    e.preventDefault();
    if (password === "") {
      formErrors.password = "Required";
    } else if (password === undefined) {
      formErrors.password = "Required";
    } else if (!passwordReg.test(password) && password !== "") {
      formErrors.password =
        "At least 1 number, upper & lower case and special character and minimum 8 characters";
    } else {
      formErrors.password = "";
    }

    if (confirm === "") {
      setFormErrors({ ...formErrors, confirm: "Required" });
    } else if (password === undefined) {
      setFormErrors({ ...formErrors, confirm: "Required" });
    } else if (password !== confirm) {
      setFormErrors({
        ...formErrors,
        confirm: "Confirm password should be same as new password",
      });
    } else if (!passwordReg.test(confirm) && confirm !== "") {
      setFormErrors({
        ...formErrors,
        confirm:
          "At least 1 number, upper & lower case and special character and minimum 8 characters",
      });
    } else {
      setFormErrors({ ...formErrors, confirm: "" });
    }

    if (
      formErrors.password === "" &&
      formErrors.confirm === "" &&
      password !== "" &&
      confirm !== "" &&
      password === confirm &&
      code !== undefined &&
      code !== ""
    ) {
      let data = {
        resetCode: code ? code : "",
        password: password,
        confirmPassword: confirm,
      };
      submitResetPasswordWithOtp(data);
    }
  };

  function submitResetPasswordWithOtp(data) {
    setLoading({ ...loading, update: true });

    AxiosInstance(9000, "application/json")
      .post(`/admin/resetPassword`, data)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setEnable(true);
          showNotification({
            type: "success",
            message: "Success",
            description: "New password created successfully",
          });
          history.push(`/resetSuccess`);
          /*localStorage.clear(); */
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "Wrong user not found",
            description: "Error occured" || res.message,
          });
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: "Error occured",
          description: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="container-fluid bg_layout2">
      <div className="row">
        <div className="logo_img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-lg-6 col-sm-6 col-md-12 col-xs-12">
          <div className="login">
            <h3 className="title_text1">Reset Password</h3>
            <p className="welcome_text">
              Your new password must be different to previously used password.
            </p>
            <form onSubmit={onVerifyOtp} data-test="login_form">
              <div className="form-group form_group  text-left">
                <label className="theme-form-label">
                  New Password
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg inputPosition"
                  error={formErrors.password}
                  formControl={{
                    type: isRevealPwd ? "text" : "password",
                    placeholder: "New password", 
                    name: "password",
                    value: password,
                    onChange: (e) => {
                      if (
                        !e.target.value.match(passwordReg) ||
                        !e.target.value === ""
                      ) {
                        setFormErrors({
                          ...formErrors,
                          password:
                            "At least 1 number, upper & lower case and special character and minimum 8 characters",
                        });
                      } else {
                        setFormErrors({ ...formErrors, password: "" });
                      }
                      setPassword(e.target.value);
                    },
                  }}
                />
                <img
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  className="password_icon1"
                  src={isRevealPwd ? eyeClose : eyeOpen}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  alt="eye_icon"
                />

                <div>
                  <FormErrorText error={formErrors.password} />
                </div>
              </div>
              <div className="form-group form_group  text-left">
                <label className="theme-form-label">
                  Confirm Password
                  <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                  </span>
                </label>
                <InputFormComponent
                  className="form-control form-control-lg mb-3"
                  error={formErrors.confirm}
                  formControl={{
                    //   type: isRevealPwd2 ? "text" : "password",
                    type: "password",
                    placeholder: "Confirm password",
                    name: "confirm",
                    value: confirm,
                    onChange: (e) => {
                      if (
                        !e.target.value.match(passwordReg) ||
                        (!e.target.value === "" && password !== e.target.value)
                      ) {
                        setFormErrors({
                          ...formErrors,
                          confirm:
                            "Confirm password should be same as new password",
                        });
                      } else if (password !== e.target.value) {
                        formErrors.confirm =
                          "Confirm password should be same as new password";
                      } else {
                        formErrors.confirm = "";
                      }
                      setConfirm(e.target.value);
                    },
                  }}
                />
                {/* <img
                title={isRevealPwd2 ? "Hide password" : "Show password"}
                src={isRevealPwd2 ? eyeClose : eyeOpen}
                onClick={() => setIsRevealPwd2((prevState) => !prevState)}
                alt="eye_icon"
              /> */}

                <FormErrorText error={formErrors.confirm} />
              </div>
              <button className="btn_login">RESET PASSWORD</button>

              {/* <div className="para-text text-left">
              <p> Your Password is a easily guessable, You can do better.</p>
            </div>
            <div className="para text-left">
              <p>At least 1 Upper Case Letter (A-Z)</p>
              <p>At least 1 number (0-9)</p>
              <p>At least 8 characters</p>
            </div> */}
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-md-0 hidden_in_mobile">
          <div className="row login_content_margin">
            <div className="login_content">
           

              <h1 className="heading_text">There you go!</h1>
              <p>Now you can reset your</p>
              <p>password</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
