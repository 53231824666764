import React from "react";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { notification, Button, Modal } from "antd";
import deleteIcon from "../../assets/img/delete.svg";
import commonstyles from "../../styles/Management_Common.module.css";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import FormErrorText from "../FormErrorText";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import { alphaNum, ipAddress } from "../RegularExp/RegularExp";
import "antd/dist/antd.css";
import AntSelectComponent from "../ReusableComponent/AntSelectComponent";

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

const originalHeaders = [
  { _id: 1, status: 1, name: "IP Address", value: "ip" },
  { _id: 2, status: 1, name: "Site", value: "siteId" },
  { _id: 3, status: 1, name: "Action", value: "" },
];

const initialFormState = {
  ipAddress: "",
  siteId: "",
};

export default function BlackListip() {
  const { confirm } = Modal;
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [websiteType, setwebsiteType] = useState([]);
  const [sortOrder, setSortorder] = useState({
    sortField: "",
    sortValue: "",
  });
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const timeoutId = useRef();
  const [initialTime, setInitialTime] = useState(true);

  const [columns, setColumns] = useState([
    {
      title: "IP Address",

      visible: false,
      key: "ip",
      render: (text, record, index) => (
        <div>
          <span title={record?.ip ? record?.ip : ""}>
            {record?.ip ? record?.ip : "-"}
          </span>
        </div>
      ),
      sorter: (a, b) => a - b ,
    },
    {
      title: "Site",

      visible: false,
      key: "siteId",
      render: (text, record, index) => (
        <div>
          <span>{record?.siteId ? record?.siteId : " -"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "",
      render: (text, record, index) => (
        <img
          src={deleteIcon}
          className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
          onClick={() => showDeleteConfirm(record._id)}
          alt="Delete IP Address"
          title="Delete IP Address"
          data-testid="deleteIcon"
        />
      ),
    },
  ]);

  function onChange(pagination, filters, sorter, extra) {
    /* column filter based on columnKey name and order */
    console.log(pagination, filters, sorter, extra, "table onchange");
    setSortorder({ ...sortOrder, sortFiled: sorter?.columnKey });
    var sortValue =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    console.log(sortValue, "sortValue");
    setSortorder({ ...sortOrder, sortValue: sortValue });
    getproductList(pageSize, pageNumber, true, {
      sortField: sorter?.columnKey,
      sortValue: sortValue,
    });
  }

  useEffect(() => {
    getWebsiteDetail();
    set_new_header(headers);
    setPageSize(25);
    setNextPage(1);
    const filter = {
      website: "",
    };
    getproductList(
      25,
      1,
      false,
      ""
      
    );
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("productList");
      localStorage.removeItem("selectedIds");
    };
  }, []);
  function showDeleteConfirm(id) {
    // console.log(id);
    confirm({
      title: "Are you sure want to delete this ?",
      icon: <ExclamationCircleOutlined />,
      // content: "Some descriptions",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteIpAddress(id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  function deleteIpAddress(id) {
    let data = { id: id };

    console.log(typeof data, data, "deleteIP Address");

    AxiosInstance(9000, "application/json")
      .delete(`admin/ip`, { data: data })
      .then((res) => {
        // console.log(res);
        if (res && res?.data && res?.status == "200") {
          showNotification({
            type: "success",
            message: "Success",
            description: "IP Address deleted successfully",
          });

          getproductList(pageSize, pageNumber, true, "");
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Failed to delete IP Address",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function getWebsiteDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/siteList`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          setwebsiteType(res.data?.data);
          console.log("datassss", res.data?.data);
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get website",
        };
        showNotification(error);
        // return er;
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** FETCH USER LIST *** //
  function getproductList(pageSize, nextPage, type, sorter) {
    if (!type) {
      setIsLoading(true);
    }
    // *** Initial value of siteId, filter, sortField, sortValue is "" *** //

    var sortField = sortOrder.sortField;
    var sortValue = sortOrder.sortValue;

    // *** If Filter selected to website *** //

    if (sorter !== "") {
      sortField = sorter.sortField;
      sortValue = sorter.sortValue;
    }
    var saveFilter = [
      {
        sorter: {
          sortField,
          sortValue,
        },
      },
    ];

    AxiosInstance(9000, "application/json")
      .get(
        `admin/ip?pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=${sortField}&sortValue=${sortValue}`
      )
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            // console.log(res.data.data?.length);
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            localStorage.setItem("productList", JSON.stringify(res.data?.data));
            setProductList(res.data?.data);
            setPagination(res.data.pagination);
          } else {
            setProductList([]);
            setPagination({});
          }
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        // setIsLoading(true);
      });
  }

  function handlepaginationChanges(value, type) {
    console.log(value, type, "value");
    //  setPageNumber(value);
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getproductList(value, 1, filter, false, "");
    } else if (type === "nextPage") {
      setNextPage(value);
      getproductList(pageSize, value, filter, false, "");
    }
  }

  const showModal = () => {
    
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditForm("")
    setFormErrors("")
  };
  function handleFormInput(e) {
    let { name, value } = e.target;
    console.log(name, value, "log");

    if (name === "ipAddress") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      if (!e.target.value.match(ipAddress) || e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter the valid  IP Address",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "siteId") {
      if (!value && value === "") {
        formErrors.siteId = "Required";
      } else {
        formErrors.siteId = "";
      }
    }
    setEditForm({ ...editForm, [name]: value });
  }

  const handleOk = (e) => {
    // var ip = ipAdd;
    // var siteIdAdd = siteId;
    let data = {
      ip: editForm.ipAddress,
      siteId: editForm.siteId,
    };
    console.log(data, "data");
    if (formErrors?.ipAddress !== "" || editForm?.ipAddress === "") {
      return setFormErrors({
        ...formErrors,
        ipAddress: "Please enter the valid IP Address",
      });
    } else {
      AxiosInstance(9000, "application/json")
        .post(`admin/ip`, data)
        .then((res) => {
          if (res.data.success === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "IP Address added successfully",
            });
            setEditForm(initialFormState)
            getproductList(pageSize, pageNumber, true, "");
           
          } else if (res.data.success === 422) {
            showNotification({
              type: "error",
              message: res.data.message,
              description: '',
            });
            setEditForm(initialFormState)
            getproductList(pageSize, pageNumber, true, "");
           
          }
           else if (res.status === 400) {
            showNotification({
              type: "error",
              message: "Adding IP Address failed",
              description: "Error occured",
            });
          }
        })
        .catch((error) => {
          showNotification({
            type: "error",
            message: "IP Address failed",
            description: error?.response?.data?.message,
          });
        })
        .finally(() => {});
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className={commonstyles.management_layout}>
        <div className={commonstyles.management_contents}>
          <div className={commonstyles.management_header}>
            <div className="row">
              <div className="col-lg-9 col-md-6 col-sm-6 col-xs-6">
                <h1 className={commonstyles.management_header_heading1}>
                  BlackList IP
                </h1>
                <p  className={commonstyles.management_header_P}>No logs will be captured for the below mentioned Blacklisted IP addresses. </p>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                <Button
                  type="primary"
                  onClick={showModal}
                  className="addButton"
                >
                  Add BlackList IP
                </Button>

                <Modal
                  title="Add Blacklist IP Address"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={"Add"}
                >
                  <label className="theme-form-label">
                    IP Address
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <InputFormComponent
                    className="form-control form-control-lg mb-2"
                    error={formErrors.ipAddress}
                    formControl={{
                      placeholder: "Type the IP Address",
                      name: "ipAddress",
                      value: editForm?.ipAddress,
                      onChange: handleFormInput,
                    }}
                  />
                  <div>
                    <FormErrorText error={formErrors.ipAddress} />
                  </div>
                  <label className="theme-form-label">
                    Site
                    <span className="text-danger" style={{ fontSize: "20px" }}>
                      *
                    </span>
                  </label>
                  <AntSelectComponent
                    error={formErrors.siteId}
                    className="form-control-md mb-3 show"
                    attributes={{
                      name: "siteId",
                      value: editForm?.siteId,
                      onChange: handleFormInput,
                    }}
                  >
                    <option value="" disabled>Select option</option>
                    {Array.isArray(websiteType)
                      ? websiteType.map((site) => (
                          <option key={site?._id} value={site?._id}>
                            {site?.name}
                          </option>
                        ))
                      : null}
                  </AntSelectComponent>
                  <div>
                    <FormErrorText error={formErrors.siteId} />
                  </div>
                </Modal>
              </div>
            </div>
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {IsLoading === true ? (
                <div className={commonstyles.loadingPage}>
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching Black List IP Address details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                <AntTableComponent
                  className="order_table"
                  tableProps={{
                    columns: columns,
                    dataSource: productList,
                    pagination: false,
                    onChange: onChange,
                  }}
                />
              )}
            </div>
            {!IsLoading.list &&
            Array.isArray(productList) &&
            productList.length ? (
              <Pagination
                pageSizes={pageSizes}
                data={pagination}
                pageSize={pageSize}
                nextPage={nextPage}
                handlepaginationChanges={(value, type) => {
                  setInitialTime((prev) => !prev);
                  handlepaginationChanges(value, type);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
