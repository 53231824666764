import React, { useEffect, useState } from "react";
import { useHistory, NavLink, useParams } from "react-router-dom";
import commonstyles from "../../styles/dashboardComponent.module.css";

import defaultImage from "../../assets/images/img-about.png";
import Close from "../../assets/img/close.svg"
import { notification, Dropdown, Menu, Select, Tooltip } from "antd";
import { AxiosInstance } from "../../utilities";
import moment from "moment";
import { drawGraph } from "../DrawGraph/DrawGraph";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import AntDateRangeSelectComponent from "../ReusableComponent/AntDateRangeSelectComponent";
import Re_Direct from "../../assets/icons/redirect.svg";

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

const daysObj = [
  { _id: 1, name: "Last 7 days", value: 7 },
  { _id: 2, name: "30 day back", value: 30 },
  { _id: 3, name: "60 day back", value: 60 },
  { _id: 4, name: "90 day back", value: 90 },
  { _id: 5, name: "120 day back", value: 120 },
  { _id: 6, name: "180 day back", value: 180 },
  { _id: 7, name: "1 year back", value: 365 },
  { _id: 8, name: "2 year back", value: 365 * 2 },
  { _id: 9, name: "3 year back", value: 365 * 3 },
  { _id: 10, name: "5 year back", value: 365 * 5 },
  { _id: 11, name: "Custom", value: "custom" },
];

const companyData = [
  { _id: 1, name: "HGR", value: "hgr" },
  { _id: 2, name: "IGAM", value: "igam" },
];

export default function DashboardComponent(props) {
  const history = useHistory();
  const { _id } = useParams();
  const [mostVisited, setMostVisited] = useState([]);
  const [pieChartRes, setPieChartRes] = useState([]);
  const [mostViewedProduct, setMostViewedProduct] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  const [barGrapg, setBarGrapg] = useState([]);
  const [dashGraph, setDashGraph] = useState([]);
  const [isLoadingDash, setIsLoadingDash] = useState(true);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filterDays, setFilterDays] = useState("");
  const [filterCompany, setfilterCompany] = useState();
  const [getCompanies, setGetCompanies] = useState([]);
  const [graphX, setGraphX] = useState([]);
  const [graphY, setGraphY] = useState([]);
  /* PIE CHART */
  const [graphValue, setGraphValue] = useState([]);
  const [graphLabel, setGraphLabel] = useState([]);
  const [pieHoverContent, setPieHoverContent] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [barHoverContent, setBarHoverContent] = useState([]);
  var retrievedObjectUser = JSON.parse(localStorage.getItem("userdata"));
  // console.log(retrievedObjectUser);

  useEffect(() => {
   JSON.parse(sessionStorage.getItem("dashboard_company_filter"));
    getWebsiteDetail();
    getUserCountDetail();
    getPieChartDetail();
    getMostVisitedDetail();
    getBarGraphDetail();
    getMostViewedProductDetail();
  }, [filterDays, filteredDate, filterCompany]);

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  function getWebsiteDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/siteList`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          // console.log(res.data?.data);
          setGetCompanies(res.data?.data);
        } else {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href("/")
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get website",
        };
        showNotification(error);
        // return er;
      });
  }

  function getPieChartDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/users/count`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          setPieChartRes(res.data?.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get pie chart data",
        };
        showNotification(error);
        // return er;
      });
  }

  function getUserCountDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/users/count?overall=1`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log("datassss count", res.data?.data);
          let datas =
            res.data?.data?.length > 0 && res.data?.data.filter((item) => item);
          // console.log(datas);
          let userData = datas?.length > 0 && datas.map((item) => item?.total);
          // console.log(datas?.length > 0 && datas.map((item) => item.hgr));

          setUsersCount(res.data?.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get user count details",
        };
        showNotification(error);
        // return er;
      });
  }
  // console.log(usersCount);

  function getBarGraphDetail() {
    AxiosInstance(9000, "application/json")
      .get(
        `sites?siteId=${
          filterCompany === "" || filterCompany === undefined
            ? ""
            : filterCompany
        }&duration=${
          filterDays === "" || isNaN(filterDays) || filterDays === undefined
            ? ""
            : +filterDays
        }&startDate=${
          filteredDate.startDate === "" ? "" : filteredDate.startDate
        }&endDate=${filteredDate.endDate === "" ? "" : filteredDate.endDate}`
      )
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log('datassss',res.data?.data);
          setDashGraph(res.data);
          setIsLoadingDash(false);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get bar chart data",
        };
        showNotification(error);
        // return er;
      });
  }

  function getMostViewedProductDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/items/mostVisits?pageIndex=1&perPageRecords=6`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log("datassss", res.data);
          setMostViewedProduct(res.data?.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get most visited product details",
        };
        showNotification(error);
        // return er;
      });
  }

  function getMostVisitedDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/users/mostVisits?pageIndex=1&perPageRecords=5`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log('datassss',res.data?.data);
          let data = res.data.data;
          data.length = 4;
          setMostVisited(data);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get frequent customers details",
        };
        showNotification(error);
        // return er;
      });
  }

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "short",
    });
  }

  // console.log(toMonthName(12));
  // console.log(pieChartRes);

  useEffect(() => {
    /* BAR CHART */
    // console.log(dashGraph);

    /* dashGraph?.data?.map(function (item) {
      console.log(item?.hgr);
      // setBarHoverContent(item);
      barHoverContent.push(item?.hgr)
      graphY.push(Math.round(item?.total));
      let datas = item._id.split("-");
      let year = datas[0];
      let month = datas[1];
      let date = datas[2];
      // console.log('date',date);
      // console.log('month',month);
      // console.log('year',year);

      // console.log( typeof month);
      //month bar
      if (month === month) {
        graphX.push(`${toMonthName(month)} ${date}`);
      }
    });
     let valueOfBar = dashGraph?.maxTotal;
    console.log(dashGraph);
    console.log("______________________",valueOfBar);

    var sum =graphY.map(function (num, idx) {
      return num + barHoverContent[idx];
    });
    console.log(sum); */

    dashGraph?.data?.map((item) => {
      // console.log(item);

      /* Y-Axis value */
      graphY.push(item.total);

      /* below is for X-Axis */
      /* Monthly wise (30 < duration <= 360) calculation for bar */
      if (item._id.month && item._id.year && !item._id.date) {
        graphX.push(`${toMonthName(item._id.month)} (${item._id.year})`);
      }

      /* Date wise- (duration <= 30) wise calculation */
      if (item._id.month && item._id.year && item._id.date) {
        graphX.push(`${item._id.date} ${toMonthName(item._id.month)}`);
      }

      /* Yearly wise (duration > 360) calculation */
      if (item._id.year && !item._id.date && !item._id.month) {
        graphX.push(`(${item._id.year})`);
      }
    });

    /* Max value of Y-Axis */
    let valueOfBar = dashGraph?.maxTotal;

    let bar = {
      x: graphX,
      y: graphY,
      //    x: [
      //     "Jan",
      //     "Feb",
      //     "Mar",
      //     "Apr",
      //     "May",

      //   ],
      //   y: [10, 8, 6, 4, 2],
      type: "bar",
      // hoverinfo: "y",
      // hovertemplate: `<i>HGR</i>: ${barHoverContent?.hgr ? barHoverContent?.hgr : '-'} <br><i>IGAM</i>: ${barHoverContent?.igam ? barHoverContent?.igam : '-'}`,
      /* text : '{y}',
      // text: sum,
      hovertemplate : "%{label}: </br> HGR: %{text} <br> IGAM: {sum} <br> TOTAL: %{y} ", 
      // hoverinfo: `text+x+y+${sum}`, */
      hovertemplate: dashGraph?.data?.map(
        (item) =>
          `%{label} <br><i>HGR-Dev</i>: ${
            item?.hgrincdev === 0 ? 0 : item?.hgrincdev ? item?.hgrincdev : "-"
          } <br><i>HGR</i>: ${
            item?.hgrinc === 0 ? 0 : item?.hgrinc ? item?.hgrinc : "-"
          } <br><i>TOTAL</i>: ${item?.total ? item?.total : "-"}`
      ),
      valueBar: valueOfBar,
      title: "",
      width: 0.3,
      name: "",
      marker: {
        color: [
          "#fd9069",
          "#FF4481",
          "#22205e",
          "#00008B",
          "#C7980A",
          "#F4651F",
          "#82D8A7",
          "#CC3A05",
          "#575E76",
          "#156943",
          "#0BD055",
          "#ACD338",
          "#7FAF78",
          "#7D80BE",
          "#7C5DBB",
          "#138F28",
          "#E35B78",
          "#CF2D00",
          "#70CBF8",
          "#AA4EE6",
          "#AF1D56",
          "#6D6BB2",
          "#A3E7D9",
          "#A76552",
          "#974610",
          "#EC9C7E",
          "#A56749",
          "#5E93CD",
          "#390C3C",
          "#0DB59A",
          "#5C5C74",
          "#72B5F1",
          "#F25F77",
        ],
      },
    };
    // console.log(filterDays);
    let graph = drawGraph(
      [bar],
      null,
      null,
      "bar",
      null,
      null,
      filterDays == 30 ? 900 : 500,
      200,
      null,
      null,
      null
    );
    setBarGrapg(graph);

    pieChartRes?.map((item) => {
      // console.log("item",upperCase(item?.state));
      setPieHoverContent(item);
      graphValue.push(item?.total);
      graphLabel.push(item?.siteId);
    });

    // console.log("__________-----------", barHoverContent);

    /* PIE CHART */
    let pieTrace;
    pieTrace = {
      /* values: [16, 15, 12],
      labels: ["US    ", "China     ", "USA             "], */
      values: graphValue,
      labels: graphLabel,
      /* total: `${
        totalTop === 0
          ? "No result found"
          : `<b>${totalTop}</b> <br /> Total Earning`
      }`,
      text: "Total Earning", */
      total: " ",
      name: "",
      // hoverinfo: "label+percent+name",
      // hovertemplate: `<i>Total Users</i>: ${pieHoverContent?.total ? pieHoverContent?.total : "-"} <br><i>Members</i>: ${pieHoverContent?.logged ? pieHoverContent?.logged : "-"}  <br><i>Guest Users</i>: ${pieHoverContent?.guest ? pieHoverContent?.guest : "-"}`,
      hovertemplate: pieChartRes?.map(
        (item) =>
          `<i>Total Users</i>: ${
            item?.total ? item?.total : "-"
          } <br><i>Members</i>: ${
            item?.logged ? item?.logged : "-"
          }  <br><i>Guest Users</i>: ${item?.guest ? item?.guest : "-"}`
      ),

      insidetextorientation: "radial",
      type: "pie",
      hole: 0.3,
      textinfo: "none",
      marker: {
        colors: ["#fd9069", "#FF4481", "#22205e"],
      },
    };
    let chart = drawGraph(
      [pieTrace],
      null,
      null,
      "pie",
      null,
      null,
      230,
      200,
      null,
      null,
      null
    );
    setPieChart(chart);
    setGraphLabel([]);
    setGraphValue([]);
    setGraphX([]);
    setGraphY([]);
  }, [pieChartRes, dashGraph]);

  /* let metaData= usersCount?.length > 0 && usersCount.map((item) => item?.total);

  console.log(metaData[0]?.total);
 */

  let totalUsersCount =
    Math.abs(usersCount[0]?.total) > 999
      ? Math.sign(usersCount[0]?.total) *
          (Math.abs(usersCount[0]?.total) / 1000).toFixed(1) +
        "k"
      : Math.sign(usersCount[0]?.total) * Math.abs(usersCount[0]?.total);

  // console.log(totalUsersCount);

  /* filters */

  function filterOnChange(e) {
    const { name, value } = e.target;
    if (name === "filterCompany") {
      setfilterCompany(value === "all" ? "" : value);
      setIsLoadingDash(false);
      sessionStorage.setItem("dashboard_company_filter", JSON.stringify(value));
      getBarGraphDetail()
    } else {
      setFilterDays(value);
      setIsLoadingDash(false);
      sessionStorage.setItem("dashboard_days_filter", JSON.stringify(value));
    }
  }
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    sessionStorage.setItem(
      "dashboard_date_filter",
      JSON.stringify(clonefilteredDate)
    );
  };

  function disabledDate(current) {
    // We can't select future day
    return current && current > moment().subtract(1, "days");
  }

  function mostViewed() {
    history.push(`/mostViewed/product`);
  }

  function mostViewedFrequent() {
    history.push(`/frequentCustomer/user`);
  }

  function filterOnCloseCustom() {
    setFilterDays("")
  }
  
  return isLoadingDash === true ? (
    <div className={commonstyles.loadingPage}>
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching dashboard details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={commonstyles.management_layout_bg}>
      <div className={commonstyles.management_layout}>
        <div className="row" id="lg-pl-0">
          <div className="col-lg-9">
            <div className={commonstyles.management_contents}>
              <div>
                <h1 style={{ font: "normal normal 600 27px/20px Poppins" }}>
                  Dashboard
                </h1>
                {/* <p className={commonstyles.management_header_P}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              </div>
              <div>
                <h3 className={commonstyles.heading2}>
                  {" "}
                  Hi{" "}
                  {`${
                    retrievedObjectUser?.firstName ||
                    retrievedObjectUser?.lastName
                      ? retrievedObjectUser?.firstName +
                        " " +
                        retrievedObjectUser?.lastName
                      : "-"
                  }`}{" "}
                  ! <span>Welcome Back</span>
                </h3>
              </div>

              <div className={commonstyles.threeContainers}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className={commonstyles.bluediv}>
                      <h6 className={commonstyles.count}>{totalUsersCount}</h6>
                      <p className={commonstyles.countHead}>Total Users</p>
                      <span className={commonstyles.countsubHead}>
                        Total Users accross all websites
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className={commonstyles.violetdiv}>
                      <h6 className={commonstyles.count}>
                        {usersCount[0]?.logged ? Intl.NumberFormat().format(usersCount[0]?.logged) : "0"}
                      </h6>
                      <p className={commonstyles.countHead}>Members</p>
                      <span className={commonstyles.countsubHead}>
                        Total Logged in users
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className={commonstyles.orangediv}>
                      <h6 className={commonstyles.count}>
                        {usersCount[0]?.guest ? Intl.NumberFormat().format(usersCount[0]?.guest) : "0"}
                      </h6>
                      <p className={commonstyles.countHead}>Guest Users</p>
                      <span className={commonstyles.countsubHead}>
                        Total Guest user
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 ">
                  <div className={commonstyles.white_bg1}>
                    <div className="row">
                      <div className="col-lg-4 col-md-16 col-sm-6 col-xs-12">
                      <h5 className={commonstyles.dashboard_subheading}>
                      Number of visits
                    </h5>
                      </div>
                      <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                      <div className={commonstyles.fliterHead}>
                      <div className={commonstyles.menuHead}>
                      <Menu className="filter_dropdown datefilter">
                        <div className="card">
                          <div className="card_contents">
                            <div className="">
                              <div className="card_dropdown show               ">
                                <select
                                  name="filterCompany"
                                  value={filterCompany}
                                  onChange={filterOnChange}
                                  className={commonstyles.filterDesign}
                                  style={{ cursor: "pointer" }}
                                >
                                  {Array.isArray(getCompanies)
                                    ? getCompanies.map((companies) => (
                                        <option
                                          key={companies?.name}
                                          value={companies?._id}
                                          style={{ textTransform: "uppercase" }}
                                        >
                                          {companies?.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Menu>
                      <Menu
                        className="filter_dropdown datefilter"
                        
                      >
                        <div className="card">
                          <div className="card_contents">
                            <div className="">
                              <div className="card_dropdown show ">
                                <select
                                  name="filterDays"
                                  value={filterDays}
                                  onChange={filterOnChange}
                                  className={commonstyles.filterDesignTwo}
                                  style={{ cursor: "pointer" }}
                                >
                                  {Array.isArray(filterdayArr)
                                    ? filterdayArr.map((date) => (
                                        <option
                                          key={date._id}
                                          value={date.value}
                                        >
                                          {date.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                              {filterDays === "custom" && (
                                <div className="card_dropdowns_dashboard  col-md-12">
                                  <img src={Close} onClick={filterOnCloseCustom} className="customClose"></img>
                                  <AntDateRangeSelectComponent
                                    selectProps={{
                                    
                                      placeholder: placeholder,
                                      onChange: dateRangeOnchangeHandler,
                                      name: "sessionDateRange",
                                      defaultValue: [
                                        filteredDate?.startDate
                                          ? moment(
                                              filteredDate?.startDate
                                                ? filteredDate?.startDate
                                                : ""
                                            )
                                          : "",
                                        filteredDate?.endDate
                                          ? moment(
                                              filteredDate?.endDate
                                                ? filteredDate?.endDate
                                                : ""
                                            )
                                          : "",
                                      ],
                                      format: dateFormatList,
                                      disabledDate: disabledDate,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Menu>
                    </div>
                  
                   </div>
                      
                      </div>

                    </div>
                   
                   
                    <div>
                      {/* {barGrapg} */}
                      {dashGraph?.data?.length > 0 ? (
                        barGrapg
                      ) : (
                        <div className="bg-transparent">
                          <div
                            className=""
                            style={{
                              padding: "0px",
                              textAlign: "center",
                              height: "210px",
                            }}
                          >
                            No data found !
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className={commonstyles.white_bg2}>
                    {" "}
                    <h5 className={commonstyles.dashboard_subheadingTwo}>
                      Over All website users
                    </h5>
                    {/* {pieChart} */}
                    {pieChartRes.length > 0 ? (
                      pieChart
                    ) : (
                      <div className="bg-transparent">
                        <div
                          className=""
                          style={{
                            padding: "0px",
                            textAlign: "center",
                            height: "210px",
                          }}
                        >
                          No data found !
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${commonstyles.dashboard_card} mb-4`}>
                <h5
                  // style={{ fontWeight: "bold" }}
                  className={`${commonstyles.dashboard_subheading_head} mb-3`}
                >
                  Most Viewed Products
                </h5>
                {/* <h5 className={commonstyles.dashboard_subheading}>
                      Number of visits
                    </h5> */}
                {mostViewedProduct.length > 0 && (
                  <div className={commonstyles.dashboard_seeMore}>
                    <span onClick={() => mostViewed()}>See More</span>
                  </div>
                )}

                <div className={`row`}>
                  {Array.isArray(mostViewedProduct) &&
                  mostViewedProduct.length > 0 ? (
                    mostViewedProduct.map((item) => (
                      <div className={`col-lg-4 col-md-6 col-sm-6 col-xs-12`}>
                              <NavLink to={`/product/${item._id}`}>
                        <div
                          className={`media ${commonstyles.most_viewed_products_item} d-flex`}
                          // style={{ cursor: "pointer" }}
                        >
                          {item?.image ? (
                            <img
                              className="mr-0"
                              src={item?.image}
                              alt="Image"
                              title=""
                            />
                          ) : (
                            <img
                              className="mr-0"
                              src={defaultImage}
                              alt="Image"
                              title=""
                            />
                          )}

                          <div
                            className={`${commonstyles.most_viewed_products_contents}`}
                          >
                            <h4 title={item?.itemId ? item?.itemId : ""}>
                                {item?.itemId
                                  ? item?.itemId
                                  : // .slice(0, 10) +
                                    //   (item?.itemId.length > 10 ? "..." : "")
                                    "-"}
                              <a
                                href={`https://hgrinc.com/productDetail/item/item/${
                                  item?.itemId
                                    ? item?.itemId.replaceAll("-", "")
                                    : "-"
                                }/`}
                                target="_blank"
                                rel="noreferrer noopener"
                                onClick={e => e.stopPropagation()}
                              >
                                <img
                                  className="user_image"
                                  alt="redirect"
                                  style={{
                                    marginLeft: "5px",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                  src={Re_Direct}
                                />
                              </a>
                            </h4>
                            <p>
                              {item.totalHits ? item.totalHits : 0}{" "}
                              {item.totalHits == 0 || item.totalHits == 1
                                ? "View"
                                : "Views"}
                            </p>
                            <p>
                              {item.price ? "$" + item.price : "$" + 0}{" "}
                              {/* {item.price == 0 || item.price == 1
                                ? "View"
                                : "Views"} */}
                            </p>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                    ))
                  ) : (
                    <h5 style={{ paddingLeft: "10px", textAlign: "left" }}>
                      No results found!
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className={commonstyles.frequent_customer}>
              <h1 className={commonstyles.headingFrequentCus}>
                Frequent Customers
              </h1>
              {mostVisited &&
                mostVisited?.length > 0 &&
                mostVisited?.map((item) => (
                  <>
                    <div className={commonstyles.white_bg}>
                      <div className="d-flex">
                        {/* <div className={commonstyles.proImg}>
                      <img
                        src={defaultImage}
                        height={"32px"}
                        width={"32px"}
                      ></img>
                    </div> */}
                        <NavLink to={`/user/details/${item._id}`}>
                          <div className={commonstyles.proContent}>
                            <p>{item?.name ? item?.name : item?.userId}</p>
                            <span className={commonstyles.spanBlack}>
                              {item.totalHits ? item.totalHits : 0}{" "}
                              {item.totalHits == 0 || item.totalHits == 1
                                ? "Visit"
                                : "Visits"}
                            </span>{" "}
                            <br></br>
                            <span className={commonstyles.spanTag}>
                              Last Visit{" "}
                              {item?.lastHit
                                ? moment
                                    .utc(item.lastHit)
                                    .local()
                                    .format("YYYY/MM/DD")
                                : "-"}
                            </span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </>
                ))}
              {mostVisited.length > 0 && (
                <div className={commonstyles.dashboard_seeMoreTwo}>
                  <span onClick={() => mostViewedFrequent()}>See More</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
