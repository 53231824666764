// import {
//     Box,
//     Flex,
//   } from "@chakra-ui/react";

//   import {
//     useJsApiLoader,
//     GoogleMap,
//     Marker,
//     DirectionsRenderer,
//     Circle,
//   } from "@react-google-maps/api";
//   import { useRef, useState } from "react";



//   function Location(props) {
//     const center = { lat: props.lat, lng: props.lng };
//     const { isLoaded } = useJsApiLoader({
//       googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//       libraries: ["places"],
//     });
//     const [map, setMap] = useState(/** @type google.maps.Map */ (null));
//     const [directionsResponse, setDirectionsResponse] = useState(null);

//     return (
//       <Flex
//         position="relative"
//         flexDirection="column"
//         alignItems="center"
//         h="220px "
//         w="220px"
//       >
//         <Box position="absolute" left={0} top={0} h="220px" w="220px">
//           {/* Google Map Box */}
//           <GoogleMap
//             center={center}
//             zoom={15}
//             mapContainerStyle={{ width: "100%", height: "100%" }}
//             options={{
//               zoomControl: false,
//               streetViewControl: false,
//               mapTypeControl: false,
//               fullscreenControl: false,
//             }}
//             onLoad={(map) => setMap(map)}
//           >
//             <Circle
//               radius={1200}
//               center={center}
//               onMouseover={() => console.log("mouseover")}
//               onClick={(e) => console.log("click", e)}
//               onMouseout={() => console.log("mouseout")}
//               strokeColor="transparent"
//               strokeOpacity={0}
//               strokeWeight={5}
//               fillColor="#FF0000"
//               fillOpacity={0.2}
//             />
//             <Marker position={center} />
//             {directionsResponse && (
//               <DirectionsRenderer directions={directionsResponse} />
//             )}
//           </GoogleMap>
//         </Box>

//       </Flex>
//     );
//   }

//   export default Location;
import React from "react";
import  GoogleMapReact from 'google-map-react';
import  Marker from "../ReusableComponent/marker"
import { propTypes } from "react-bootstrap/esm/Image";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function googleMaps(props) {
  console.log(props.lat, props.lng, "center")
  const defaultProps = {
    
    center: {
      lat: props.lat,
      lng: props.lng
    },
   
    zoom: 6
  };


  return (
    // Important! Always set the container height explicitly
    <div className="googleMapsCss">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals

      >
        <AnyReactComponent
          lat={props.lat}
          lng={props.lng}
        
        />
        <Marker lat={props.lat} lng={props.lng} />

      </GoogleMapReact>
    </div>
  );
}

