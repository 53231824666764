import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import jwt from "jsonwebtoken";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { emailRegx, passwordReg } from "../RegularExp/RegularExp";
import * as action from "../../Store/Actions/index";
import BackGroundImage from "../../assets/img/login_banner_img.png";
import Logo from "../../assets/img/HTS-Logo.png";
import eyeOpen from "../../assets/icons/eye_icon.svg";
import eyeClose from "../../assets/icons/eye_close.svg";

import InputComponent from "../ReusableComponent/InputComponent";
import { notification } from "antd";

const LoginComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAuthenticated = jwt.decode(localStorage.getItem("token"))
    ? true
    : false;

  const loginError = useSelector((state) => {
    return state.auth.error;
  });
  const [formError, setformError] = useState({
    user_name: "",
    password: "",
  });
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isCalled, setIsCalled] = useState(false);
  const on_password_visible = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setFormErrors({});
    setIsCalled(true);

    /*  if (isAuthenticated === true) {
      window.location.href = "/";
    } */
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "Please enter an email")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "Please enter a password")
          : !passwordReg.test(value.trim())
          ? (newformError.password = "At least 1 number, upper & lower case and special character and minimum 8 characters") :
          (newformError.password = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setLoginForm({ ...loginForm, [name]: value });
  };

  const onLogin = (e) => {
    setIsCalled(false);
    const loginData = {
      email: loginForm.email,
      password: loginForm.password,
    };
    e.preventDefault();
    const newformError = { ...formError };
    const loginFormNew = { ...loginForm };
    if (loginFormNew.email === "" && loginFormNew.password === "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "* Please enter a password";
    } else if (loginFormNew.email === "" && loginFormNew.password !== "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "";
    } else if (loginFormNew.email !== "" && loginFormNew.password === "") {
      if (!emailRegx.test(loginFormNew.email)) {
        newformError.email = "* Please enter valid email address";
      } else {
        newformError.email = "";
      }
      newformError.password = "* Please enter a password";
    } else if (
      loginFormNew.email !== "" &&
      !emailRegx.test(loginFormNew.email)
    ) {
      newformError.email = "* Please enter valid email address";
      newformError.password = "";
    } else if (
      loginFormNew.password !== "" &&
      !passwordReg.test(loginFormNew.password)
    ) {
      newformError.email = "";
      newformError.password = "At least 1 number, upper & lower case and special character and minimum 8 characters";
    }else {
      newformError.email = "";
      newformError.password = "";
    }
    setformError(newformError);
    if (newformError.email === "" && newformError.password === "") {
      dispatch(action.LoginActions(loginData, history));
    }
  };

  function forgotPassTab() {
    history.push(`/verify`);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="container-fluid bg_layout">
      <div className="row">
        <div className="logo_img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-lg-6 col-sm-6 col-md-12 col-xs-12">
          <div className="login">
            <h1 className="title_text">Sign In</h1>
            <p className="welcome_text">
              Welcome! Please login to your account.
            </p>
            {loginError !== "" && isCalled === false ? (
              <p
                style={{
                  color: "red",
                }}
                className="error_text active"
              >
                {loginError}
              </p>
            ) : (
              ""
            )}
            <form onSubmit={onLogin} data-test="login_form">
              <div className="form-group form_group ">
                <InputComponent
                  formData={{
                    id: "user_email",
                    placeholder: "User Email ID",
                    onChange: onInputChange,
                    name: "email",
                    className: "form-control form_control",
                    type: "text",
                    "aria-describedby": "user_email",
                    value: loginForm.email.trim(),
                  }}
                />
                <span className="error_text active" data-test="username_error">
                  {formError && formError.email !== "" ? formError.email : ""}
                </span>
              </div>
              <div className="form-group form_group password_icon ">
                <InputComponent
                  formData={{
                    placeholder: "Password*",
                    onChange: onInputChange,
                    className: "form-control form_control",
                    name: "password",
                    type: visible ? "text" : "password",
                    "data-test": "user_password",
                    value: loginForm.password,
                  }}
                />
                <img
                  src={visible ? eyeClose : eyeOpen}
                  alt="eye-icon"
                  onClick={on_password_visible}
                />
                <span className="error_text active" data-test="username_error">
                  {formError && formError.password !== ""
                    ? formError.password
                    : ""}
                </span>
              </div>
              <div className="forgot_password">
                <a onClick={forgotPassTab}>Forgot password?</a>
              </div>
              <button className="btn_login">LOGIN</button>
            </form>

            {/* <div className="privacy_policy">
              Terms & condition | Privacy Policy
            </div> */}
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-md-0 hidden_in_mobile">
          <div className="row login_content_margin">
            <div className="login_content">
              <h1 className="heading_text">Happy to see you!</h1>
              <p>Please Login to start your</p>
              <p>Hit tracking service</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginComponent);
