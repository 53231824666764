import React from 'react';
import { List} from "react-movable";
const MovableListComponent = (props) => {
    
    return (
        <List {...props.listProps}/>
    );
}
 
export default MovableListComponent;
