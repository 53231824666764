/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import Logo from "../../assets/img/HTS-Logo.png";
import BackGroundImg3 from "../../assets/img/bg_3.png";
import success from "../../assets/icons/SuccessTick.svg";
import { useHistory, withRouter } from "react-router-dom";
import "antd/dist/antd.css";

export default function LoginResetAlert(props) {
  const history = useHistory();

  return (
    <div className="container-fluid bg_layout3">
      <div className="row">
        <div className="logo_img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div className="login">
            <div className="back_arrow d-flex justify-content-center">
              <img
                src={success}
                className=""
                title="success"
                width={"100px"}
                height={"100px"}
              />
            </div>
            <h1 className="title_text" style={{ fontSize: "25px" }}>
              Password Reset
            </h1>
            <p className="welcome_text">
              Your password as been successfully reset. Click below to login
            </p>
            <button className="btn_login" onClick={() => history.push("/")}>
              CONTINUE
            </button>
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-md-6 hidden_in_mobile">
          <div className="row login_content_margin">
            <div className="login_content">
              <h1 className="heading_text">Awesome!</h1>
              <p>Now your all set for</p>
              <p>login</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
