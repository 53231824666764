import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Rating } from "react-simple-star-rating";
import { Button } from "antd";
import commonstyles from "../../styles/Management_Common.module.css";
import { AxiosInstance } from '../../utilities';
import AddReview from './AddReview';
import * as action from '../../Store/Actions/index';
import GoogleMapsComponent from "../GoogleMapsComponent/googleMaps";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import Re_Direct from "../../assets/icons/redirect.svg";
import BackArrow from "../../assets/icons/back_arrow.svg";
import Excel from "../../assets/img/excel.svg";
import defaultProductImg from "../../assets/images/img-about.png";


const originalHeaders = [
    { _id: 1, status: 1, name: 'Name', value: 'name' },
    { _id: 2, status: 1, name: 'Rate', value: 'rate' },
    { _id: 3, status: 1, name: "Event Type", value: "eventType" },
    { _id: 4, status: 1, name: 'Review', value: 'review' },
    { _id: 5, status: 1, name: 'Date', value: 'date' },
];

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

let dollarUSLocale = Intl.NumberFormat("en-US");

function RecommendationDetail() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({});
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [nextPage, setNextPage] = useState(1);
    const [IsLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [complete, setComplete] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: "Emp. Name",
            visible: false,
            key: "name",
            render: (text, record, index) => (
                <div>
                    <span>
                        {record?.name ? record?.name : "-"}
                    </span>
                </div>
            )
        },
        {
            title: "Rate",
            visible: false,
            key: "rate",
            render: (text, record, index) => (
                <div>
                    <span>
                        <Rating
                            readonly
                            size={20}
                            allowHover={false}
                            initialValue={record?.rate ? record?.rate : 0} />
                    </span>
                </div>
            )
        },
        {
            title: "Event Type",
            visible: false,
            key: "eventType",
            render: (text, record, index) => (
                <div>
                    <span>
                        {record?.event ? record?.event : "-"}
                    </span>
                </div>
            )
        },
        {
            title: "Review",
            visible: false,
            key: "review",
            minWidth: "200px",
            render: (text, record, index) => (
                <div>
                    <span style={{ whiteSpace: "wrap" }}>
                        {record?.review ? record?.review : "-"}
                    </span>
                </div>
            )
        },
        {
            title: "Date",
            visible: false,
            key: "date",
            render: (text, record, index) => (
                <div>
                    <span>
                        {record?.date ? record?.date : "-"}
                    </span>
                </div>
            )
        },
    ]);
    const [headers, setHeaders] = useState(originalHeaders);
    const [reviewData, setReviewData] = useState([])
    const [initialTime, setInitialTime] = useState(true);

    const renderHeader = (props) => {
        return (
            <tr>
                {columns.map((item, idx) => {
                    if (item.visible) return props.children[idx];
                })}
            </tr>
        );
    };

    const renderBody = (props) => {
        if (props.children.length > 0) {
            return (
                <tr className={props.className}>
                    {columns.map((item, idx) => {
                        if (item.visible) {
                            return props.children[idx];
                        }
                    })}
                </tr>
            );
        } else {
            return (
                <tr className="bg-transparent text-info">
                    <td colSpan="3">No results found!</td>
                </tr>
            );
        }
    };
    useEffect(() => {
        set_new_header(headers);
        fetchRecommendationDetails()
        fetchReviews()
    }, [pageSize, nextPage, complete == true])

    const logout = () => {
        sessionStorage.clear();
        dispatch(action.logout());
        window.location.href = '/';
    };

    const fetchRecommendationDetails = () => {
        let api = `recommendations/${id}`;
        AxiosInstance(9000, 'application/json')
            .get(api)
            .then((res) => {
                if (res && res.data && res.status == 200) {
                    if (res?.data?.data.length > 0) {
                        setDataSource(res.data.data)
                        setPagination(res?.data?.pagination)
                    } else {
                        setDataSource([])
                        setPagination({})
                    }
                } else if (res?.data && res?.status == 403) {
                    logout();
                }
            })
            .catch((er) => {
                return er;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchReviews = () => {
        let api = `reviews/${id}?&pageIndex=${nextPage}&perPageRecords=${pageSize}`;
        AxiosInstance(9000, 'application/json')
            .get(api)
            .then((res) => {
                if (res && res.data && res.status == 200) {
                    if (res?.data?.data.length > 0) {
                        setReviewData(res?.data?.data)
                        setPagination(res?.data?.pagination)
                    } else {
                        setReviewData([])
                        setPagination({})
                    }
                } else if (res?.data && res?.status == 403) {
                    logout();
                }
            })
            .catch((er) => {
                return er;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const set_new_header = (response) => {
        var newArr = [];
        const data = response;
        for (let i = 0; i < data.length; i++) {
            const columnsClone = [...columns];
            let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
            if (columnsClone[objIndex] !== undefined) {
                columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
                newArr.push(columnsClone[objIndex]);
            }
        }
        setColumns(newArr);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const updatePopupModal = (state) => {
        setIsModalOpen(state)
    }

    const completeApi = (state) =>{
        setComplete(state)
    } 

    function handlepaginationChanges(value, type) {
        if (type === 'pageSize') {
            setPageSize(value);
            setNextPage(1);
        } else if (type === 'nextPage') {
            setNextPage(value);
        }
    }

    return (
        <div className={commonstyles.management_layout}>
            <div className={commonstyles.management_contents}>
                <div className={commonstyles.management_header}>
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-lg-10 col-md-8 col-sm-8 col-xs-4">
                            <div className="back_arrow d-flex">
                                <img
                                    src={BackArrow}
                                    onClick={() => history.goBack()}
                                    className="cursor-pointer"
                                    title="back"
                                    width={"25px"}
                                    height={"50px"}
                                    style={{ marginTop: "9px" }}
                                    alt="back"
                                />
                                <h1
                                    className="backBtn-arrow"
                                    style={{ font: "normal normal 600 32px/60px Poppins" }}
                                >
                                    Recommendation details
                                </h1>
                            </div>
                        </div>

                        {/* <div className="col-lg-2 col-md-4 col-sm-4 col-xs-8">
                            <span style={{ display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", font: "600 14px Poppins" }}
                              onClick={exportExcel}
                            >
                                <img style={{ marginRight: "1%" }} src={Excel} alt="export" />
                                <span>Export</span>
                            </span>
                        </div> */}
                    </div>
                </div>

                <div>
                    <div>
                        {dataSource.map((item) => (
                            <>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className={commonstyles.management_contents_product}>
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <GoogleMapsComponent
                                                        lat={item?.user?.location?.coordinates[1]}
                                                        lng={item?.user?.location?.coordinates[0]}
                                                    />
                                                </div>
                                                <div className="col-lg-7">
                                                    <div class=" bd-highlight">
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Name : </h1>
                                                            <p>{item?.user?.name ? item?.user?.name : "-"}</p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Email : </h1>
                                                            <p>{item?.user?.email ? item?.user?.email : "-"}</p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Phone : </h1>
                                                            <p>{item?.user?.phone ? item?.user?.phone : "-"}</p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Location : </h1>
                                                            <p>
                                                                {item?.user?.location?.address?.city
                                                                    ? item?.user?.location?.address?.city + ", "
                                                                    : ""}
                                                                {item?.user?.location?.address?.state
                                                                    ? item?.user?.location?.address?.state + ", "
                                                                    : ""}
                                                                {item?.user?.location?.address?.country
                                                                    ? item?.user?.location?.address?.country
                                                                    : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className={commonstyles.management_contents_product}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <img
                                                        className="productImg"
                                                        alt="redirect"
                                                        src={item?.image ? item?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htsp") : defaultProductImg}
                                                    />
                                                </div>
                                                <div className="col-lg-8">
                                                    <div class=" bd-highlight">
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Inventory No : </h1>
                                                            <p>
                                                                {item?.item?.itemId ? item?.item?.itemId : "-"}{" "}
                                                                <a
                                                                    href={`https://hgrinc.com/productDetail/item/item/${item?.item?.itemId
                                                                        ? item?.item?.itemId.replaceAll("-", "")
                                                                        : "-"
                                                                        }/`}
                                                                    target="_blank"
                                                                    rel="noreferrer noopener"
                                                                >
                                                                    <img
                                                                        className=""
                                                                        alt="redirect"
                                                                        style={{ marginLeft: "5px" }}
                                                                        src={Re_Direct}
                                                                    />
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Product Name : </h1>
                                                            <p>{item?.item?.name ? item?.item?.name : "-"}</p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Product price : </h1>
                                                            <p>$
                                                                {item?.item?.price
                                                                    ? dollarUSLocale.format(item?.item?.price)
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Product Location : </h1>
                                                            <p>
                                                                {item?.item?.location?.address
                                                                    ? item?.item?.location?.address
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                        <div class="p-0 flex-fill bd-highlight d-flex">
                                                            <h1>Product Category : </h1>
                                                            <p>{item?.item?.category ? item?.item?.category : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="row" style={{ justifyContent: "space-between" }}>
                        <div className="col-lg-3  col-md-9 col-sm-9 col-xs-3">
                            <h1 className={commonstyles.management_header_heading}>
                                Comments
                            </h1>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                            <Button
                                type="primary"
                                onClick={showModal}
                                className="addButton"
                            >
                                Add Review
                            </Button>
                            {isModalOpen === true && <AddReview
                                isModalOpen={isModalOpen}
                                updatePopupModal={updatePopupModal}
                                id={id}
                                complete = {completeApi}
                            />}
                        </div>
                    </div>
                    <div className={`${commonstyles.management_table} table-responsive`}>
                        {IsLoading === true ? (
                            <div className={commonstyles.loadingPage}>
                                <tr className="bg-transparent">
                                    <td colSpan="100%">
                                        <LoadingIndicator loadingText="Fetching Recommendation details..." />
                                    </td>
                                </tr>
                            </div>
                        ) : (
                            <AntTableComponent
                                className="order_table"
                                tableProps={{
                                    sortDirections: ["descend", "ascend"],
                                    columns: columns,
                                    dataSource: reviewData,
                                    pagination: false,
                                    //   onChange: onChange,
                                    components: {
                                        header: {
                                            row: (props) => renderHeader(props),
                                        },
                                        body: {
                                            row: (props) => renderBody(props),
                                        },
                                    },
                                }}
                            />
                        )}
                    </div>
                    {!IsLoading.list && Array.isArray(reviewData) && reviewData.length ? (
                        <Pagination
                            pageSizes={pageSizes}
                            data={pagination}
                            pageSize={pageSize}
                            nextPage={nextPage}
                            handlepaginationChanges={(value, type) => {
                                setInitialTime((prev) => !prev);
                                handlepaginationChanges(value, type);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default RecommendationDetail