/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import BackArrow from "../../assets/icons/Left_arrow.svg";
import Logo from "../../assets/img/HTS-Logo.png";
import BackGroundIMG from "../../assets/img/bg_1.png";
import { useHistory, withRouter } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import { button, notification } from "antd";
import "antd/dist/antd.css";

const ForgotPassword = (props) => {
  const history = useHistory();

  const [formErrors, setFormErrors] = useState({});
  const [isCalled, setIsCalled] = useState(false);

  useEffect(() => {
    setFormErrors({});
    setIsCalled(true);
  }, []);

  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : null;

  if (email === undefined || email === "" || email == "" || email == null) {
    showNotification({
      type: "error",
      message: "Error",
      description: "Email id not found",
    });
    history.push(`/`);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className="container-fluid bg_layout">
      <div className="row">
        <div className="logo_img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-lg-6 col-sm-6 col-md-12 col-xs-12">
          <div className="login">
            <h1 className="title_text" style={{ fontSize: "25px" }}>
              Check your Email
            </h1>
            <p className="welcome_text">
              We have sent a password reset link to
            </p>
            <p className="email-text">{email ? email : "-"}</p>
            {/* <p className="welcome_text">
            Didn't receive the email?{" "}
            <a style={{ color: "#22205e" }} href='/forgot'>Click to resend</a>{" "}
          </p> */}

            <div className="back_arrow d-flex justify-content-center">
              <img
                src={BackArrow}
                onClick={() => history.push("/")}
                className="cursor-pointer"
                title="back"
                width={"25px"}
                height={"22px"}
                style={{ marginTop: "9px" }}
              />
              <h1 className="backBtn-arrow">Back to Login</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-6 col-md-0 hidden_in_mobile">
          <div className="row login_content_margin">
            <div className="login_content">
              <h1 className="heading_text">Opps!</h1>
              <p>Don't worry! It happens,</p>
              <p>we got you</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
