import React, { useEffect, useState } from "react"
import { Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { arrayMove } from 'react-movable';
import { AxiosInstance } from '../../utilities';
import commonstyles from '../../styles/Management_Common.module.css';
import LoadingIndicator from '../ReusableComponent/LoadingIndicator';
import AntTableComponent from '../ReusableComponent/AntTableComponent';
import Pagination from '../ReusableComponent/Pagination';
import MovableListComponent from '../ReusableComponent/MovableListComponent';
import * as action from '../../Store/Actions/index';
import Excel from '../../assets/img/excel.svg';
import arrowDown from '../../assets/img/arrow_down.svg';

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];

const originalHeaders = [
    { _id: 1, status: 1, name: 'Name', value: 'name' },
    { _id: 2, status: 1, name: 'Email', value: 'email' },
    { _id: 3, status: 1, name: 'Phone', value: 'phone' },
    { _id: 4, status: 1, name: 'Country', value: 'country' },
    { _id: 5, status: 1, name: 'Inventory', value: 'inventory' },
    { _id: 6, status: 1, name: 'Product Name', value: 'productName' },
    { _id: 7, status: 1, name: 'Price', value: 'price' },
    { _id: 8, status: 1, name: 'Category', value: 'category' },
    { _id: 9, status: 1, name: 'Location', value: 'location' },
    { _id: 10, status: 1, name: 'Visits', value: 'visits' },
    { _id: 11, status: 1, name: 'Favorite', value: 'favorite' },
    { _id: 12, status: 1, name: 'Last Visit', value: 'lastVisit' },
    { _id: 13, status: 1, name: 'Probability', value: 'probability' },
];

export default function Recommendation(props) {
    const dispatch = useDispatch();
    const [IsLoading, setIsLoading] = useState(false)
    const [recommendationData, setRecommendationData] = useState([])
    const [pagination, setPagination] = useState({});
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [nextPage, setNextPage] = useState(1);
    const [initialTime, setInitialTime] = useState(true);
    const [headers, setHeaders] = useState(originalHeaders);
    const [items, setItems] = useState(originalHeaders);
    const [columns, setColumns] = useState([
        /* {
          visible: false,
          key: "select",
          render: (text, record, index) => (
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={record.isChecked}
              onChange={(e) => childCheckbox(e, record)}
            ></input>
          ),
        }, */
        {
            title: 'Name',
            visible: false,
            key: 'name',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.user?.name ? record?.user?.name : ' -'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Email',
            visible: false,
            key: 'email',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.user?.email ? record?.user?.email : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Phone',
            visible: false,
            key: 'phone',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.user?.phone ? record?.user?.phone : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Country',
            visible: false,
            key: 'country',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>
                            {record?.user?.location?.address?.country
                                ? record?.user?.location?.address?.country
                                : '-'}
                        </span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Product Name',
            visible: false,
            key: 'productName',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.item?.name ? record?.item?.name : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Price ($)',
            visible: false,
            key: 'price',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.item?.price ? record?.item?.price : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Category',
            visible: false,
            key: 'category',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.item?.category ? record?.item?.category : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Visits',
            visible: false,
            key: 'visits',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.totalHits ? record?.totalHits : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        // {
        //     title: 'Favotite',
        //     visible: false,
        //     key: 'isFavorite',
        //     render: (text, record, index) => (
        //         <NavLink to={`/user/details/${record?._id}`}>
        //             <div>
        //                 <span>{record?.isFavorite ? record?.isFavorite : '-'}</span>
        //             </div>
        //         </NavLink>
        //     ),
        // },
        {
            title: 'Last Visit',
            visible: false,
            key: 'lastVisit',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.lastHit ? moment.utc(record.lastHit).local().format('MM/DD/YYYY') : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
        {
            title: 'Probability (%)',
            visible: false,
            key: 'probability',
            render: (text, record, index) => (
                <NavLink to={`/recommendation/details/${record?._id}`}>
                    <div>
                        <span>{record?.probability ? record?.probability.toFixed(2) : '-'}</span>
                    </div>
                </NavLink>
            ),
        },
    ]);
    const renderHeader = (props) => {
        return (
            <tr>
                {columns.map((item, idx) => {
                    if (item.visible) return props.children[idx];
                })}
            </tr>
        );
    };

    const renderBody = (props) => {
        if (props.children.length > 0) {
            return (
                <tr className={props.className}>
                    {columns.map((item, idx) => {
                        if (item.visible) {
                            return props.children[idx];
                        }
                    })}
                </tr>
            );
        } else {
            return (
                <tr className="bg-transparent text-info">
                    <td colSpan="3">No results found!</td>
                </tr>
            );
        }
    };

    useEffect(() => {
        set_new_header(headers);
        fetchRecommendations()
    }, [pageSize, nextPage])

    const logout = () => {
        sessionStorage.clear();
        dispatch(action.logout());
        window.location.href = '/';
    };

    const fetchRecommendations = () => {
        let api = `recommendations?&pageIndex=${nextPage}&perPageRecords=${pageSize}`;
        setIsLoading(true)
        AxiosInstance(9000, 'application/json')
            .get(api)
            .then((res) => {
                if (res && res.data && res.status == 200) {
                    if (res?.data?.data?.length > 0) {
                        setRecommendationData(res.data.data)
                        setPagination(res.data.pagination)
                        setIsLoading(false)
                    } else {
                        setRecommendationData([])
                        setPagination({})
                        setIsLoading(false)
                    }
                } else if (res?.data && res?.status == 403) {
                    logout();
                }
            })
            .catch((er) => {
                return er;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const menu = (
        <Menu className="columns_list">
            <MovableListComponent
                listProps={{
                    values: items,
                    onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
                    renderList: ({ children, props }) => (
                        <ul {...props} style={{ listStyleType: 'none' }}>
                            {children}
                        </ul>
                    ),
                    renderItem: ({ value, props }) => (
                        <li {...props} key={value._id} className="columns_list_item">
                            {value.name !== 'select' && (
                                <>
                                    <input
                                        type="checkbox"
                                        checked={value.status === 1 ? true : false}
                                        onChange={(event) =>
                                            on_field_check_handler(event, value._id)
                                        }
                                    />
                                    <span>{value.name ? value.name : ''}</span>
                                </>
                            )}
                        </li>
                    ),
                }}
            />
        </Menu>
    );

    const rearrange = (oldIndex, newIndex) => {
        oldIndex = oldIndex === 0 ? 2 : oldIndex;
        newIndex = newIndex === 0 ? 2 : newIndex;
        if (oldIndex !== 1 && newIndex !== 1) {
            setItems(arrayMove(items, oldIndex, newIndex));
            const itemClone = [...items];
            let values = rearrange_array(itemClone, oldIndex, newIndex);
            fetchNewOrder(values);
        }
    };

    const rearrange_array = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
            let i = newIndex - arr.length + 1;
            while (i--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

        return arr;
    };

    const fetchNewOrder = async (items) => {
        let newStatus = [];
        items.map((item, index) => {
            let obj = {};
            obj.order = index;
            obj.status = item.status;
            obj.value = item.value;
            obj.name = item.name;
            obj._id = item._id;
            newStatus.push(obj);
        });
        setItems(newStatus);
        set_new_header(newStatus);
    };

    const set_new_header = (response) => {
        var newArr = [];
        const data = response;
        for (let i = 0; i < data.length; i++) {
            const columnsClone = [...columns];
            let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
            if (columnsClone[objIndex] !== undefined) {
                columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
                newArr.push(columnsClone[objIndex]);
            }
        }
        setColumns(newArr);
    };

    const on_field_check_handler = (e, id) => {
        const itemClone = [...items];
        let objIndex = items.findIndex((obj) => obj._id === id);
        itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
        setItems(itemClone);
        fetchNewOrder(items);
    };

    function handlepaginationChanges(value, type) {
        if (type === 'pageSize') {
            setPageSize(value);
            setNextPage(1);
        } else if (type === 'nextPage') {
            setNextPage(value);
        }
    }

    const exportExcel = () => {
        console.log("Export function call.")
    }

    return (
        <div className={commonstyles.management_layout}>
            <div className={commonstyles.management_contents}>
                <div className={commonstyles.management_header}>
                    <div className="row">
                        <div className="col-lg-10 col-md-8 col-sm-8 col-xs-3">
                            <h1>Recommendations</h1>
                        </div>
                        <div
                            className="col-lg-2 col-md-4 col-sm-4 col-xs-9"
                            style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                alignContent: 'center',
                            }}
                        >
                            {/* <div>
                                <span
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        font: '600 14px Poppins',
                                    }}
                                    onClick={exportExcel}
                                >
                                    <img style={{ marginRight: '5%' }} src={Excel} alt="export" />
                                    <span>Export</span>
                                </span>
                            </div> */}
                            <div>
                                <Dropdown
                                    overlay={menu}
                                    className="usermanage_table_filter_columns ml-3"
                                >
                                    <div
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Columns <img alt="" src={arrowDown} width={10} height={20} />
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
                >
                    {/* <div
                        className={`${commonstyles.management_contents_search_bar} input-group`}
                    >
                        <input
                            type="search"
                            name="search"
                            className={`${commonstyles.form_control} form-control`}
                            aria-label="Search"
                            placeholder="Search by Name, Email and Phone"
                            aria-describedby="search-addon"
                            value={search}
                            // onChange={}
                            onChange={handleInput}
                        />
                        <img src={Search} alt="" />
                    </div> */}

                    {/* <Dropdown
                        // overlay={filterMenu}
                        className="usermanage_table_filter_columns ml-3"
                    >
                        <div className="ant-dropdown-link">
                            Filter <img src={arrowDown} width={10} height={20} />
                        </div>
                    </Dropdown> */}

                    {/* <Dropdown
                        overlay={menu}
                        className="usermanage_table_filter_columns ml-3"
                    >
                        <div
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            Columns <img alt="" src={arrowDown} width={10} height={20} />
                        </div>
                    </Dropdown> */}
                </div>

                <div className={`${commonstyles.management_table} table-responsive`}>
                    {IsLoading === true ? (
                        <div className={commonstyles.loadingPage}>
                            <tr className="bg-transparent">
                                <td colSpan="100%">
                                    <LoadingIndicator loadingText="Fetching recommendation details..." />
                                </td>
                            </tr>
                        </div>
                    ) : (
                        <AntTableComponent
                            className="order_table"
                            tableProps={{
                                columns: columns,
                                sortDirections: ['descend', 'ascend'],
                                dataSource: recommendationData,
                                pagination: false,
                                components: {
                                    header: {
                                        row: (props) => renderHeader(props),
                                    },
                                    body: {
                                        row: (props) => renderBody(props),
                                    },
                                },
                            }}
                        />
                    )}
                </div>

                {!IsLoading.list && Array.isArray(recommendationData) && recommendationData.length ? (
                    <Pagination
                        pageSizes={pageSizes}
                        data={pagination}
                        pageSize={pageSize}
                        nextPage={nextPage}
                        handlepaginationChanges={(value, type) => {
                            setInitialTime((prev) => !prev);
                            handlepaginationChanges(value, type);
                        }}
                    />
                ) : null}
            </div>
        </div>
    )

}