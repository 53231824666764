import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import Sidebar from "./SidebarComponent";

const LayoutComponent = (props) => {
  return (
    <section>
      <Header/>
      <div className="row mx-0">
        <Sidebar/>
        <div className="col-lg-10 col-sm-12 col-md-9 px-0">{props.children}</div>
      </div>
    </section>
  );
};

export default LayoutComponent;
