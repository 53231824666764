import styles from "./Sidebar.module.css";
import logoutIcon from "../../assets/icons/logout_icon.svg";
import * as action from "../../Store/Actions/index";
import "./Sidebar.module.css";
import { useDispatch } from "react-redux";

export default function FooterComponent() {
  const dispatch = useDispatch();

  const logout = () => {
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = "/";
  };
  
  return (
    <div className={styles.admin_sidebar_footer}>
      <img
        src={logoutIcon}
        id="my-img"
        onClick={() => logout()}
        alt="logout"
        title="Logout"
        width={32}
        height={32}
        align="center"
        style={{ cursor: "pointer" }}
      ></img>
      <h1 className="backBtn-arrow cursor-pointer" onClick={() => logout()}>
        Logout
      </h1>
    </div>
  );
}
