import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import Logo from "../../assets/img/HTS-Logo.png";
import { useHistory, withRouter } from "react-router-dom";
import { emailRegx } from "../RegularExp/RegularExp";
import InputComponent from "../ReusableComponent/InputComponent";
import { AxiosInstance } from "../../utilities";
import { button, notification } from "antd";
import "antd/dist/antd.css";
import BackGroundIMG from "../../assets/img/bg_1.png";
import BackArrow from "../../assets/icons/Left_arrow.svg";


export default function Verification(props) {
  const history = useHistory();
  const [formError, setformError] = useState({
    password: "",
  });
  const [emailForm, setEmailForm] = useState({
    email: "",
  });
  const [loading, setLoading] = useState({ details: false, update: false });

  useEffect(() => {
    setformError({});
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "Please enter an email")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter valid email address")
          : (newformError.email = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setEmailForm({ ...emailForm, [name]: value });
  };

  const onLogin = (e) => {
    e.preventDefault();
    const loginData = {
      email: emailForm.email,
    };
    // console.log(loginData);
    const newformError = { ...formError };
    const emailFormNew = { ...emailForm };
    if (emailFormNew.email === "") {
      newformError.email = "* Please enter an email address";
    } else if (emailFormNew.email === "" && emailFormNew.password !== "") {
      newformError.email = "* Please enter an email address";
    } else if (emailFormNew.email !== "") {
      if (!emailRegx.test(emailFormNew.email)) {
        newformError.email = "* Please enter valid email address";
      } else {
        newformError.email = "";
      }
    } else if (
      emailFormNew.email !== "" &&
      !emailRegx.test(emailFormNew.email)
    ) {
      newformError.email = "* Please enter valid email address";
    } else {
      newformError.email = "";
    }
    setformError(newformError);
    setLoading({ ...loading, update: true });

    // console.log(emailFormNew);
    // console.log(newformError);

    if( emailFormNew.email !== ""  && newformError.email === ""){
      // console.log("called the apiiiiii");
      AxiosInstance(9000, "application/json")
      .post(`/admin/forgotPassword`, loginData)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Forgot password link sent successfully",
          });
          localStorage.setItem("email", emailForm?.email);
          history.push(`/success`);
        //   localStorage.clear();
        } else if (res.status === 400) {
          showNotification({
            type: "error",
            message: "Wrong Email or User not found",
            description: "Error occured" || res.message,
          });
        }
      })
      .catch((error) => {
        showNotification({
          type: "error",
          message: "Error occured",
          description: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
    }

    
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    
      <div className="container-fluid bg_layout1">
      <div className="row">
        <div className="logo_img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-lg-6  col-sm-6 col-md-12 col-xs-12 p-0">
      
          <div className="login">
            <h1 className="title_text" style={{fontSize:'25px'}}>Forgot Password?</h1>
            <p className="welcome_text">
              Please enter the Email ID associated with your account.
            </p>
            
            <form onSubmit={onLogin} data-test="login_form">
              <div className="form-group form_group text-left">
                <InputComponent
                  formData={{
                    id: "user_email",
                    placeholder: "User Email ID",
                    onChange: onInputChange,
                    name: "email",
                    className: "form-control form_control",
                    type: "text",
                    "aria-describedby": "user_email",
                    value: emailForm.email.trim(),
                    autocomplete:"off"
                  }}
                />
                <span className="error_text active" data-test="username_error">
                  {formError && formError.email !== "" ? formError.email : ""}
                </span>
              </div>
              <button className="btn_login">SUBMIT</button>
            </form>
            <div className="back_arrow d-flex justify-content-center" style={{marginTop:'30px'}}>
            <img
              src={BackArrow}
              onClick={() => history.push("/")}
              className="cursor-pointer"
              title="back"
              width={"25px"}
              height={"22px"}
              style={{marginTop:'9px'}}
            />
            <h1 className="backBtn-arrow cursor-pointer" onClick={() => history.push("/")}>Back to Login</h1>
          </div>
          </div>
      </div>
      
      <div className="col-lg-6 col-sm-6 col-md-12 hidden_in_mobile">
          <div className="row login_content_margin">
            <div className="login_content">
              <h1 className="heading_text">Opps!</h1>
              <p>Don't worry! It happens,</p>
              <p>we got you</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

