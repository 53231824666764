import React, { useEffect } from "react";
import { Route, Router, withRouter, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/fonts/stylesheet.css";
import "./App.css";
import { useDispatch } from "react-redux";
import AuthGuard from "./AuthGuard";
import * as action from "./Store/Actions/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/globals.css";
import LayoutComponent from "./Components/LayoutComponent/LayoutComponent";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import VerificationComponent from "./Components/LoginComponent/Verification";
import SuccessPage from "./Components/LoginComponent/SuccessPageComponent";
import ForgotComponent from "./Components/LoginComponent/ForgotPassword";
import LoginResetAlertComponent from "./Components/LoginComponent/VerifiedLoginResetAlert";
import LogoutComponent from "./Components/LoginComponent/Logout";
import UserListComponent from "./Components/UserComponent/UserListManagement";
import ProductListComponent from "./Components/ProductComponent/ProductListManagement";
import UserDetailsComponent from "./Components/UserComponent/UserDetailsManagement";
import ProductDetailsComponent from "./Components/ProductComponent/ProductDetails";
import DashboardComponent from "./Components/DashboardComponent/DashboardComponent";
import BlackList from "./Components/BlacklistIP/BlackListip";
import Recommendation from "./Components/Recommendation/Recommendation"
import RecommendationDetail from "./Components/Recommendation/RecommendationDetail";


const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.authCheckState());
  }, []);


  return (
    <Router history={props.history}>
      <Switch>
        <Route path="/" exact component={LoginComponent} />
        <Route path="/verify" exact component={VerificationComponent} />
        <Route path="/success" exact component={SuccessPage} />
        {/* Email checking for Reset password */}
        <Route path="/forgot/:code" exact component={ForgotComponent} />
        <Route path="/resetSuccess" exact component={LoginResetAlertComponent} />
        <LayoutComponent>
        <AuthGuard path="/home" exact component={LogoutComponent} />
        <AuthGuard path="/dashboard" exact component={DashboardComponent} />
        <AuthGuard path="/user" exact component={UserListComponent} />
        <AuthGuard path="/frequentCustomer/user" exact component={UserListComponent} />
        <AuthGuard path="/product" exact component={ProductListComponent}/>
        <AuthGuard path="/mostViewed/product" exact component={ProductListComponent} />
        <AuthGuard path="/user/details/:id" exact component={UserDetailsComponent} />
        <AuthGuard path="/product/:id" exact component={ProductDetailsComponent} />
        <AuthGuard path="/blacklistIP" exact component={BlackList} />
        <AuthGuard path="/recommendation" exact component={Recommendation}/>
        <AuthGuard path="/recommendation/details/:id" exact component={RecommendationDetail}/>
        </LayoutComponent>
      </Switch>
    </Router>
  );
};

export default withRouter(App);
