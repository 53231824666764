import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.css";
import arrowDown from "../../assets/img/arrow_down.svg";
import arrowUp from "../../assets/img/arrow_Up.svg";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/HTS-Logo.png";
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { menus } from "./menus";
import FooterComponent from "./FooterComponent";
import * as action from "../../Store/Actions/index";
import "./Sidebar.module.css";
import { useDispatch } from "react-redux";
export default function SidebarComponent() {
  const [activeAccordian, setActiveAccordian] = useState(-1);
  // const [data, setData]= useState(menus);
  // const [loading, setLoading]= useState(false)

  /*  useEffect(()=>{
    // setLoading(true);

  },[data]) */
  const dispatch = useDispatch();

  const logout = () => {
    sessionStorage.clear();
    dispatch(action.logout());
    window.location.href = "/";
  };
  function buildSubLevelMenu(childMenus) {
    if (Array.isArray(childMenus) && childMenus.length > 0) {
      const subMenus = childMenus.map((menu) => {
        return (
          <NavLink
            to={menu.route}
            className={`list-group-item ${styles.list_group_sub_item} ${styles.side_recommendation}`}
            key={menu.title}
            activeClassName={styles.menu_active}
          >
            {menu.title}
          </NavLink>
        );
      });
      return (
        <ul
          className={` ${styles.list_group_flush} list-group list-group-flush  ${styles.side_recommendation} `}
        >
          {subMenus}
        </ul>
      );
    }
    return null;
  }

  const activateTab = (e, i) => {
    console.log(activeAccordian, i);
    if (activeAccordian == i) {
      setActiveAccordian(-1);
    } else {
      setActiveAccordian(i);
    }
  };

  const menuItems = menus.map((menu) => {
    let permissions = JSON.parse(localStorage.getItem("permissions")) || [];
    if (localStorage.getItem("role") === "super_admin") {
      permissions = ["Super Admin"];
    }
    permissions.push("null");
    const hasSubMenu = Array.isArray(menu.child);

    const slidestyle = {
      padding: "0",
      backgroundColor: "#fff",
      borderBottom: "none",
    };
    const SlideStyleCard = {
      width: "auto",
      border: "none",
    };
    const slidestylebtn = {
      padding: "0",
      width: "100%",
      textAlign: "inherit",
    };
    const slidesubmenu = {
      padding: "10px",
    };

    return hasSubMenu ? (
      permissions.length > 1 ? (
        permissions.map((subMenu, indx) => {
          if (subMenu === menu.id) {
            return (
              <div id="accordion">
                <Accordion>
                  <Card className="acc_card" style={SlideStyleCard}>
                    <Card.Header
                      className={styles.acc_card_header}
                      onClick={(e) => activateTab(e, indx)}
                      style={slidestyle}
                    >
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={indx}
                        style={slidestylebtn}
                      >
                        <li
                          className={`list-group-item list-group-item ${styles.list_group_item}  ${styles.side_recommendation}`}
                          key={indx}
                        >
                          <img
                            src={menu.img}
                            alt={menu.imgAlt}
                            className={`${styles.sb_image}`}
                          />
                          <img
                            src={menu.imgActive}
                            alt={menu.imgAlt}
                            className={`${styles.sb_image_active}`}
                          />
                          {menu.title}
                          {activeAccordian == indx ? (
                            <img
                              src={arrowUp}
                              alt="arrow"
                              className={`${styles.sb_arrow}`}
                            />
                          ) : (
                            <img
                              src={arrowDown}
                              alt="arrow"
                              className={`${styles.sb_arrow}`}
                            />
                          )}
                        </li>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={indx} className="">
                      <Card.Body className="" style={slidesubmenu}>
                        {buildSubLevelMenu(menu.child)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            );
          }
        })
      ) : (
        <div key={menu.title}>
          <li
            className={`list-group-item list-group-item ${styles.list_group_item}  ${styles.side_recommendation}`}
            key={menu.title}
          >
            <img src={menu.img} alt={menu.imgAlt} />
            {menu.title}
          </li>
          {buildSubLevelMenu(menu.child)}
        </div>
      )
    ) : permissions.length > 1 ? (
      permissions.map((subMenu) => {
        if (subMenu === menu.id) {
          return (
            <NavLink
              // exact
              to={menu.route}
              className={`list-group-item list-group-item ${styles.list_group_item}  ${styles.side_recommendation}`}
              key={menu.title}
              activeClassName={styles.menu_active}
            >
              <img
                src={menu.img}
                alt={menu.imgAlt}
                className={`${styles.sb_image}`}
              />
              <img
                src={menu.imgActive}
                alt={menu.imgAlt}
                className={`${styles.sb_image_active}`}
              />
              {menu.title}
            </NavLink>
          );
        }
      })
    ) : (
      <NavLink
        // exact
        to={menu.route}
        className={`list-group-item list-group-item ${styles.list_group_item} ${styles.side_recommendation}`}
        key={menu.title}
        activeClassName={styles.menu_active}
      >
        <img
          src={menu.img}
          alt={menu.imgAlt}
          className={`${styles.sb_image}`}
        />
        <img
          src={menu.imgActive}
          alt={menu.imgAlt}
          className={`${styles.sb_image_active}`}
        />
        {menu.title}
      </NavLink>
    );
  });

  return (
    <>
      <div className="showIn_mobile">
        <nav className="navbar navbar-expand-xs">
          <a className="navbar-brand" href="/dashboard">
            <div className={` ${styles.logo_brand}`}>
              <img src={logo} alt="logo" />
            </div>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/dashboard">
                  Dashboard
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/user">
                  User
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/product">
                  Product
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => logout()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div
        className={`col-lg-2 col-md-3 col-sm-0 px-0 ${styles.management_sidebar}`}
      >
        <Accordion>
          <div className={` ${styles.logo}`}>
            <NavLink to={`/dashboard`}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <ul className={`list-group list-group-flush ${styles.list_group} ${styles.side_recommendation}`}>
            {menuItems}
          </ul>
        </Accordion>
        <FooterComponent />
      </div>
    </>
  );
}
