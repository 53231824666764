import axios from "axios";
// import { logout } from "./Components/ReusableComponent/logout";

export const AxiosInstance = (port, contentType) => {
  const service = 8000;
// alert(port)
  const Axios = axios.create({
    baseURL:
    process.env.REACT_APP_NODE_ENV === "production" ||
    process.env.REACT_APP_NODE_ENV === "staging"
    ? `${process.env.REACT_APP_API_BASE_URL}/api/v1`
    : `${process.env.REACT_APP_API_BASE_URL}:${port}/api/v1/`,
});
  Axios.defaults.headers.common["Authorization"] =
    typeof window !== "undefined" && localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : null;
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return Axios;
};
