/* eslint-disable no-redeclare */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "../../utilities";
import {
  notification,
  Dropdown,
  Menu,
  Select,
  Switch,
  Button,
  Input,
  Tooltip
} from "antd";
import AntSelectComponent from "../ReusableComponent/AntSelectComponent";
import commonstyles from "../../styles/Management_Common.module.css";
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import arrowDown from "../../assets/img/arrow_down.svg";
import Excel from "../../assets/img/excel.svg";
import defaultProductImg from "../../assets/images/img-about.png";
import Search from "../../assets/img/search_icon.svg";
import Re_Direct from "../../assets/icons/redirect.svg";
import AntTableComponent from "../ReusableComponent/AntTableComponent";
import Pagination from "../ReusableComponent/Pagination";
import LoadingIndicator from "../ReusableComponent/LoadingIndicator";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { arrayMove } from "react-movable";
import "antd/dist/antd.css";
import MovableListComponent from "../ReusableComponent/MovableListComponent";

const defaultPageSize = 25;
const pageSizes = [25, 60, 80];
const { Option } = Select;
const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Inventory No", value: "itemId" },
  { _id: 3, status: 1, name: "Product Name", value: "name" },
  { _id: 5, status: 1, name: "Price", value: "price" },
  { _id: 10, status: 1, name: "Category", value: "category" },
  { _id: 12, status: 1, name: "Location", value: "location" },
  { _id: 4, status: 1, name: "Website", value: "siteId" },

  { _id: 7, status: 1, name: "Total Visits", value: "totalHits" },
  { _id: 8, status: 1, name: "Total Users", value: "userCount" },
  { _id: 11, status: 1, name: "Login Users", value: "loginUserCount" },
  { _id: 6, status: 1, name: "Interest", value: "preference" },

  { _id: 9, status: 1, name: "Last Visit", value: "lastHit" },
];

const filters = [
  { id: 1, isChecked: false, name: "Website", value: "website" },
  { id: 2, isChecked: false, name: "Category", value: "category" },
  { id: 3, isChecked: false, name: "Location", value: "location" },
];
const initialFormState = {
  sign: "",
  price: "",
};
const ProductList = (props) => {
  const urlPath = props.match.url;
  // console.log(props.match.url, "title props");
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [IsLoading, setIsLoading] = useState(false);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [notSold, setNotSold] = useState("");
  const [editForm, setEditForm] = useState(initialFormState);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [website, setWebsite] = useState( JSON.parse(sessionStorage.getItem("saveFilter"))?.siteId || "");
  const [category, setCategory] = useState(
    JSON.parse(sessionStorage.getItem("saveFilter"))?.categories || ""
  );
  const [location, setLocation] = useState(
    JSON.parse(sessionStorage.getItem("saveFilter"))?.location || ""
  );
  const [priceType, setPriceType] = useState("");
  const [websiteType, setwebsiteType] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [locationType, setLocationType] = useState([]);
  const [filterShipName, setFilterShipName] = useState("ALL");
  const [filterCategoryName, setFilterCategoryName] = useState("ALL");
  const [filterLocationName, setFilterLocationName] = useState("ALL");
  const [sortOrder, setSortorder] = useState({
    sortField: "",
    sortValue: "",
  });
  const timeoutId = useRef();
  const [initialTime, setInitialTime] = useState(true);
  const [show, setShow] = useState(true);
  const [websiteChecked, setWebsiteChecked] = useState(false);
  const [categoryChecked, setCategoryChecked] = useState(false);
  const [locationChecked, setLocationChecked] = useState(false);
  const [columns, setColumns] = useState([
    /* {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    }, */
    {
      title: "Inventory No",
      visible: false,
      key: "itemId",
      render: (text, record, index) => (
        <div>
          <NavLink to={`/product/${record?._id}`}>
            <span title={record?.itemId ? record?.itemId : ""}>
              {record?.itemId ? record?.itemId : "-"}
            </span>
          </NavLink>

          <a
            href={`https://hgrinc.com/productDetail/item/item/${
              record?.itemId ? record?.itemId.replaceAll("-", "") : "-"
            }/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className=""
              title="Click Here"
              style={{ marginLeft: "5px" }}
              src={Re_Direct}
            />
          </a>
        </div>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Product Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          {/* {console.log(record?._id)} */}
          <div>
            <span>
              {record?.image ? (
                <img 
                className="user_image" alt="" src={record?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htst")} />
              ) : (
                <img className="user_image" alt="" src={defaultProductImg} />
              )}
              <div className="hide">
                {record?.image ? <img alt="" src={record?.image.replace("images.hgrinc.com", "image.hgrinc.com/tr:n-htsh")} /> : ""}
              </div>
              { record?.name ? (record?.name?.length > 15 ? 
              <Tooltip 
              title={record?.name}
              >
                {record?.name.slice(0, 15)}...
              </Tooltip> : record?.name) : " -"}
            </span>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Website",
      visible: false,
      key: "siteId",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>{record?.siteId ? record?.siteId : "-"}</span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Price ($)",
      visible: false,
      key: "price",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>
              {record?.price
                // ? dollarUSLocale.format(record?.price.toFixed(0))
                ?  dollarUSLocale.format(record?.price)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Category",
      visible: false,
      key: "category",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>{record?.category ? record?.category : "-"}</span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Location",
      visible: false,
      key: "location",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>{record?.location ? record?.location : "-"}</span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Last Visit",
      visible: false,
      key: "lastHit",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>
              {record.lastHit
                ? moment
                    .utc(record.lastHit)
                    .local()
                    .format("MM/DD/YYYY")
                : "-"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Visits",
      visible: false,
      key: "totalHits",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>
              {record?.totalHits
                ? Intl.NumberFormat().format(record?.totalHits)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Interest",
      visible: false,
      key: "preference",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>
              {record?.preference
                ? Intl.NumberFormat().format(record?.preference)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Users",
      visible: false,
      key: "userCount",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>
              {record?.userCount
                ? Intl.NumberFormat().format(record?.userCount)
                : "0"}
            </span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
    {
      title: "Login Users",
      visible: false,
      key: "loginUserCount",
      render: (text, record, index) => (
        <NavLink to={`/product/${record?._id}`}>
          <div>
            <span>{record?.loginUserCount ? record?.loginUserCount : "0"}</span>
          </div>
        </NavLink>
      ),
      sorter: urlPath === "/product" ? (a, b) => a - b : "",
    },
  ]);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  function onChange(pagination, filters, sorter, extra) {
    /* column filter based on columnKey name and order */
    console.log(pagination, filters, sorter, extra, "table onchange");
    // setSortorder({ ...sortOrder,  });
    var sortValue =
      sorter?.order === "ascend"
        ? "ASC"
        : sorter?.order === undefined
        ? ""
        : "DESC";
    setSortorder({ ...sortOrder, sortField: sorter?.columnKey, sortValue: sortValue });
    getproductList(
      pageSize,
      nextPage,
      website,
      true,
      {
        sortField: sorter?.columnKey,
        sortValue: sortValue,
      },
      search,
      notSold,
      "",
      category,
      location
    );
  }

  useEffect(() => {
    getWebsiteDetail();
    getCategoryDetail();
    getLocationDetail();
    set_new_header(headers);
    var saveFilter = JSON.parse(sessionStorage.getItem("saveFilter"));
    setPageSize(saveFilter?.pagesize ?? 25);
    setNextPage(saveFilter?.nextPage ?? 1);
    getproductList(
      saveFilter?.pageSize || 25,
      1,
      website,
      false,
      saveFilter?.sorter || sortOrder,
      search,
      saveFilter?.soldout || "",
      saveFilter?.clearInput || "",
      category || saveFilter?.categories || "",
      location || saveFilter?.location || ""
    );
    setNotSold(saveFilter?.soldout);
    localStorage.removeItem("selectedIds");
    return () => {
      localStorage.removeItem("productList");
      localStorage.removeItem("selectedIds");
    };
  }, []);

  function getWebsiteDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/siteList`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log("datassss", res.data?.data);
          setwebsiteType(res.data?.data);
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get website",
        };
        showNotification(error);
        // return er;
      });
  }

  function getCategoryDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/categories`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log('datassss',res.data?.data);
          setCategoryType(res.data?.data);
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get category",
        };
        showNotification(error);
        // return er;
      });
  }

  function getLocationDetail() {
    AxiosInstance(9000, "application/json")
      .get(`/locations`)
      .then((res) => {
        // console.log('datassss',res.data);

        if (res && res.data && res.status == "200") {
          // console.log('datassss',res.data?.data);
          setLocationType(res.data?.data);
        } else if (res.status === "403") {
          localStorage.clear();
          history.push(`/`);
        }
      })
      .catch((er) => {
        // console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get location",
        };
        showNotification(error);
        // return er;
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  // switch toggle button to filter guest user //
  const onChangeSwitch = (checked) => {
    if (checked === true) {
      setNotSold(1);
      var saveFilter = JSON.parse(sessionStorage.getItem("saveFilter"));
      setPageSize(saveFilter?.pagesize ?? 25);
      setNextPage(saveFilter?.nextPage ?? 1);
      console.log(saveFilter, "saveFilter");
      getproductList(
        saveFilter?.pageSize || 25,
        1,
        website,
        false,
        sortOrder || "",
        search,
        1,
        saveFilter?.clearInput || "",
        category,
        location
      );
    }
    if (checked === false) {
      setNotSold("");
      var saveFilter = JSON.parse(sessionStorage.getItem("saveFilter"));
      setPageSize(saveFilter?.pagesize ?? 25);
      setNextPage(saveFilter?.nextPage ?? 1);
      console.log(saveFilter, "saveFilter");
      getproductList(
        saveFilter?.pageSize || 25,
        saveFilter?.nextPage || 1,
        website,
        false,
        saveFilter?.sorter || "",
        search,
        "",
        saveFilter?.clearInput || "",
        category,
        location
      );
    }
  };
  // *** FETCH USER LIST *** //
  function getproductList(
    pageSize,
    nextPage,
    filterarr,
    type,
    sorter,
    search,
    soldoutProducts,
    clearInput,
    categories,
    location
  ) {
    if (!type) {
      setIsLoading(true);
    }
    // *** Initial value of siteId, filter, sortField, sortValue is "" *** //
    var siteId = filterarr;
    var soldout = soldoutProducts;
    var sortField = sorter.sortField;
    var sortValue = sorter.sortValue;
    var sign = clearInput ? clearInput?.sign : editForm.sign;
    var price = clearInput ? clearInput?.price : editForm.price;

    // *** If Filter selected to website *** //
    // if (filterarr && filterarr.name === "website") {
    //   siteId = filterarr?.value?._id == "all" ? "" : filterarr?.value?._id;
    // }
    // if (sorter !== "") {
    //   sortField = sorter.sortField;
    //   sortValue = sorter.sortValue;
    // }
    var saveFilter = {
      pageSize: pageSize,
      nextPage: nextPage,
      siteId: siteId,
      sorter: sorter,
      search: search,
      soldout: soldout,
      sign: sign,
      price: price,
      categories: categories,
      location: location
    };

    var api;
    if (props.match.url === "/product") {
      api = `items/getItems?siteId=${siteId}&pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=${sortField}&sortValue=${sortValue}&search=${
        search || ""
      }&notSold=${soldout}&filter=${sign}&price=${price}&category=${categories}&location=${location}`;
    } else {
      api = `items/getItems?siteId=${siteId}&pageIndex=${nextPage}&perPageRecords=${pageSize}&sortField=totalHits&sortValue=DESC&search=${
        search || ""
      }&notSold=${soldout}&filter=${sign}&price=${price}&category=${categories}&location=${location}`;
    }

    sessionStorage.setItem("saveFilter", JSON.stringify(saveFilter));
    AxiosInstance(9000, "application/json")
      .get(api)
      .then((res) => {
        if (res && res.data && res.status === 200) {
          // console.log(res.data.data);
          if (res.data.data?.length > 0) {
            // console.log(res.data.data?.length);
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            // setIsLoader(false);
            // console.log(res.data)
            localStorage.setItem("productList", JSON.stringify(res.data?.data));
            setProductList(res.data?.data);
              setPagination(res.data.pagination);
          } else {
            setProductList([]);
            setPagination({});
          }
        } else if (res?.data && res?.status == "403") {
          sessionStorage.clear();
          localStorage.clear();
    window.location.href = "/";
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleInput(e) {
    const { name, value } = e.target;
    console.log(name, value, "name,value");
    if (name === "search") {
      getproductList(pageSize, 1, website, "", sortOrder, value, notSold, "", category, location);
      setSearch(value);
    }
  }

  function handlepaginationChanges(value, type) {

    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getproductList(value, 1, website, false, sortOrder, search, notSold, "", category, location);
    } else if (type === "nextPage") {
      setNextPage(value);
      getproductList(pageSize, value, website, false, sortOrder, search, notSold, "", category, location);
    }
  }

  function filterChange(name, value) {
    if (name === "website") {
      setWebsite(value._id === "all" ? "" : value._id);
      setFilterShipName(value.name);
      getproductList(pageSize, 1, value._id === "all" ? "" : value._id, false, sortOrder, search, notSold, "", category, location);
    }
  }

  function categoryChange(name, value) {
    if (name === "category") {
      setCategory(value._id === "All" ? "" : value._id);
      setFilterCategoryName(value._id);
      getproductList(pageSize, 1, website, false, sortOrder, search, notSold, "", value._id === "All" ? "" : value._id, location);
    }
  }

  function locationChange(name, value) {
    if (name === "location") {
      setLocation(value._id === "All" ? "" : value._id);
      setFilterLocationName(value._id);
      getproductList(pageSize, 1, website, false, sortOrder, search, notSold, "", category, value._id === "All" ? "" : value._id);
    }
  }

  //TO SHOW TABLE HEADERS BASED ON STATUS AND COLUMNS FILTER
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  const renderBody = (props) => {
    // console.log(columns);
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    if (data.value === "website") {
      setWebsiteChecked(data.isChecked)
    } else if (data.value === "category") {
      setCategoryChecked(data.isChecked)
    } else {
      setLocationChecked(data.isChecked)
    }
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];

    cloneSelectFilter.forEach((item, index) => {
      if (item.id === data.id) {
        console.log(item.id, "itemId");
        console.log(data.id, "dataId");
        item.isChecked = checked;
        if (checked) {
          console.log(filters.isChecked, "selectFilter");
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters.splice(index, 1);
        }
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    if (!e.target.checked && data.id === 1) {
      setWebsite("");
      getproductList(pageSize, 1, "", false, sortOrder, search, notSold, "", category, location);

    }
    if (!e.target.checked && data.id === 2) {
      setCategory("")
      getproductList(pageSize, 1, website, false, sortOrder, search, notSold, "", "", location);
    }
    if (!e.target.checked && data.id === 3) {
      setLocation("")
      getproductList(pageSize, 1, website, false, sortOrder, search, notSold, "", category, "");
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      console.log("rearrange", values);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    console.log("rearrange_array", arr);

    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj.name = item.name;
      obj._id = item._id;
      newStatus.push(obj);
    });
    console.log(items, "items in products list");
    // console.log("fetch new order", newStatus);
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    // console.log(e);
    // console.log("id", id);
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    // console.log("objIndex", objIndex);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    // console.log("items", itemClone);
    // console.log("new order", items);
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.name !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span>{value.name ? value.name : ""}</span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {!websiteChecked &&
              selectedFilters.find((x) => x === "website") === "website" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterShipName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(websiteType)
                      ? websiteType.map((item) => (
                          <span
                            key={item._id}
                            onClick={() => filterChange("website", item)}
                          >
                            <label style={{ textTransform: "uppercase" }}>
                              {item.name}
                            </label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {!categoryChecked &&
              selectedFilters.find((x) => x === "category") === "category" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterCategoryName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(categoryType)
                      ? categoryType.map((item) => (
                          <span
                            key={item._id}
                            onClick={() => categoryChange("category", item)}
                          >
                            <label style={{ textTransform: "uppercase" }}>
                              {item._id}
                            </label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {!locationChecked &&
              selectedFilters.find((x) => x === "location") === "location" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterLocationName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(categoryType)
                      ? locationType.map((item) => (
                          <span
                            key={item._id}
                            onClick={() => locationChange("location", item)}
                          >
                            <label style={{ textTransform: "uppercase" }}>
                              {item._id}
                            </label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}

        
          </div>
        </div>
      </div>
    </Menu>
  );
  function handleFormInput(e) {
    const { name, value } = e.target;
    console.log(e, "price1");
    setEditForm({ ...editForm, [name]: value });
  }
  function submitPrice(e) {
    const { name, value } = e.target;
    let data = {
      sign: editForm.sign,
      price: editForm.price,
    };
    //  var data1 = data.sign
    //  var data2 = data.price
    {
      editForm === initialFormState
        ? ""
        : getproductList(pageSize, nextPage, website, false, sortOrder, search, notSold, "", category, location);
      setShow(editForm === initialFormState ? true : false);
    }
  }
  function clearPrice() {
    setEditForm(initialFormState);
    console.log(editForm, "editform2");
    getproductList(
      pageSize,
      1,
      website,
      false,
      sortOrder,
      search,
      notSold,
      initialFormState,
      category,
      location
    );
    setShow(true);
  }
  const exportExcel = () => {
    AxiosInstance(9000, "application/json")
      .get(`items/excel?siteId=${website}&search=${search}&notSold=${notSold}&sortField=${sortOrder.sortField}&sortValue=${sortOrder.sortValue}&filter=${editForm.sign}&price=${editForm.price}&category=${category}&location=${location}`, {
      responseType: 'blob'
      })
      .then(response => {
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `Products.csv`) //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        })
      .catch((er) => {
        return er;
      })
  };

  return (
    <div className={commonstyles.management_layout}>
      <div className={commonstyles.management_contents}>
        <div className={commonstyles.management_header}>
          <div className="row">
            <div className="col-lg-3 col-md-9 col-sm-9 col-xs-3">
              <h1 className={commonstyles.management_header_heading}>
                {props.match.url == "/product"
                  ? "Products"
                  : "Most Viewed Products"}
              </h1>
            </div>
            <div className="col-lg-9 col-md-3 col-sm-3 col-xs-9">
              <div className="row mt-2">
                <div className="col-lg-4 ">
                  <AntSelectComponent
                    className="custom_select mt-1 mb-3 show"
                    attributes={{
                      name: "sign",
                      value: editForm?.sign,
                      onChange: handleFormInput,
                    }}
                  >
                    <option value="" disabled>
                      Price Select Option
                    </option>
                    <option value="gt">Price greater than $</option>
                    <option value="lt">Price lesser than $</option>
                    <option value="gte">Price greater than equal to $</option>
                    <option value="lte">Price lesser than equal to $</option>
                    <option value="eq">Price equal to $</option>
                    <option value="ne">Price not equal to $</option>
                  </AntSelectComponent>
                </div>
                <div className="col-lg-5 customfilter">
                  <InputFormComponent
                    className="custom_select   custominput"
                    formControl={{
                      placeholder: "Type the price",
                      type: "number",
                      name: "price",
                      value: editForm?.price,
                      onChange: handleFormInput,
                    }}
                  />
                
                    <Button
                      type="submit"
                      onClick={submitPrice}
                      className="custombtn"
                    >
                      Search
                    </Button>
                 
                    <Button
                      type="submit"
                      onClick={clearPrice}
                      className="custombtn"
                    >
                      Clear
                    </Button>
                 
                </div>

                <div className="col-lg-3" style={{display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "top", marginTop: "5px"}}>
                  <div>      
                      <Switch
                        // defaultChecked
                        // className={commonstyles.btntoggle}
                        checkedChildren="Available Products"
                        unCheckedChildren="All Products"
                        onChange={onChangeSwitch}
                        checked={notSold === 1}
                      />
                  </div>
                  <div>
                    <span style={{display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer", font: "600 14px Poppins"}} onClick={exportExcel}>
                        <img style={{marginRight: "5%"}} src={Excel} alt="export" />
                        <span>Export</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                name="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search Product name..."
                aria-describedby="search-addon"
                value={search}
                onChange={handleInput}
              />
              <img src={Search} alt="" />
            </div>

            <Dropdown
              overlay={filterMenu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div className="ant-dropdown-link">
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>

            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>

          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading === true ? (
              <div className={commonstyles.loadingPage}>
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching Products details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  sortDirections: ["descend", "ascend"],
                  dataSource: productList,
                  pagination: false,
                  onChange: onChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
        {!IsLoading.list && Array.isArray(productList) && productList.length ? (
          <Pagination
            pageSizes={pageSizes}
            data={pagination}
            pageSize={pageSize}
            nextPage={nextPage}
            handlepaginationChanges={(value, type) => {
              setInitialTime((prev) => !prev);
              handlepaginationChanges(value, type);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
export default ProductList;
