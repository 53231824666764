import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Modal, notification } from "antd";
import { useDispatch } from 'react-redux';
import { Rating } from "react-simple-star-rating";
import { AxiosInstance } from '../../utilities';
import FormErrorText from "../FormErrorText";
import * as action from '../../Store/Actions/index';
import InputFormComponent from "../ReusableComponent/InputFormComponent";
import AntSelectComponent from "../ReusableComponent/AntSelectComponent";
import TextAreaComponent from '../ReusableComponent/textAreaComponent';

const initialFormState = {
    name: '',
    rating: 0,
    review: '',
    type: ''
};

function AddReview({ isModalOpen, updatePopupModal, id, complete }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [editForm, setEditForm] = useState(initialFormState);
    const [formErrors, setFormErrors] = useState({});
    const [dataType, setDataType] = useState([]);

    useEffect(() => {
        AxiosInstance(9000, 'application/json')
            .get('/events')
            .then((res) => {
                if (res && res.data && res.status == 200) {
                    setDataType(res.data.data)
                } else if (res?.data && res?.status == 403) {
                    logout();
                }
            })
            .catch((er) => {
                return er;
            })
    }, [])

    const handleOk = (e) => {
        if (editForm?.name === "") {
            return setFormErrors({
                ...formErrors,
                name: "Please enter name",
            });
        }
        if (editForm?.rating === 0) {
            return setFormErrors({
                ...formErrors,
                rating: "Please select Rating",
            });
        }
        if (editForm?.type === "") {
            return setFormErrors({
                ...formErrors,
                type: "Please select type",
            });
        } else {
            const body = {
                name: editForm.name,
                rate: editForm.rating,
                review: editForm.review,
                eventType: editForm.type,
                parent: id
            }
            AxiosInstance(9000, 'application/json')
                .post('/reviews', body)
                .then((res) => {
                    if (res && res.data && res.status == 200) {
                        showNotification({
                            type: "success",
                            message: "Success",
                            description: "Review added successfully",
                        });
                        setEditForm(initialFormState)
                        handleCancel()
                        complete(true)
                        return setFormErrors("");
                    } else if (res?.data && res?.status == 403) {
                        logout();
                    }
                })
                .catch((er) => {
                    return er;
                })
        }
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    const logout = () => {
        sessionStorage.clear();
        dispatch(action.logout());
        window.location.href = '/';
    };

    const handleCancel = () => {
        updatePopupModal(false)
        setEditForm({
            name: '',
            review: '',
            rating: 0
        })
        complete(false)
        setFormErrors("")
    };

    function handleFormInput(e) {
        let { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value });
    }

    const handleStarClick = (e) => {
        editForm.rating = e
    }

    return (
        <Modal
            title="Add Review"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={"Add"}
        >
            <label className="theme-form-label">
                Employee Name
                <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                </span>
            </label>
            <InputFormComponent
                className="form-control form-control-lg mb-2"
                error={formErrors.name}
                formControl={{
                    placeholder: "Type the Employee Name",
                    name: "name",
                    value: editForm?.name,
                    onChange: handleFormInput,
                }}
            />
            <div>
                <FormErrorText error={formErrors.name} />
            </div>

            <label className="theme-form-label" style={{ marginTop: "20px" }}>
                Event Type
                <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                </span>
            </label>
            <AntSelectComponent
                error={formErrors.type}
                className="form-control-md mb-3 show"
                attributes={{
                    name: "type",
                    value: editForm?.type,
                    onChange: handleFormInput,
                }}
            >
                <option value="" disabled>Select option</option>
                {Array.isArray(dataType)
                    ? dataType.map((type) => (
                        <option key={type?._id} value={type?._id}>
                            {type?.eventType}
                        </option>
                    ))
                    : null}
            </AntSelectComponent>
            <div>
                <FormErrorText error={formErrors.type} />
            </div>

            <label className="theme-form-label" style={{ marginTop: "20px" }}>
                Rating
                <span className="text-danger" style={{ fontSize: "20px" }}>
                    *
                </span>
            </label>
            <div>
                <Rating
                    size={20}
                    allowHover={false}
                    disableFillHover={true}
                    onClick={handleStarClick}
                    initialValue={0}
                />
            </div>
            <div>
                <FormErrorText error={formErrors.rating} />
            </div>

            <label className="theme-form-label" style={{ marginTop: "20px" }}>
                Review
            </label>
            <TextAreaComponent
                className="form-control form-control-lg mb-2"
                error={formErrors.review}
                formControl={{
                    placeholder: "Type the Review",
                    name: "review",
                    value: editForm?.review,
                    onChange: handleFormInput,
                }}
            />
            <div>
                <FormErrorText error={formErrors.review} />
            </div>
        </Modal>
    )
}

export default AddReview