import { useHistory } from 'react-router-dom';

export default function HeaderComponent() {
  const history = useHistory();
  function redirect() {
    history.push("/editprofile");
  }

  return <></>;
}
